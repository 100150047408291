/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Common = () => {
  return (
    <div className="common">
      <div className="common__content"></div>
    </div>
  );
};

export default Common;
