import qurbanss from "../img/qurbanss.jpeg";
import qurbanovijatrik from "../img/qurbanovijatrik.jpeg";

//last serial: 2

const QurbanProjectList = [
  {
    _ID: "sdqmeq001.1",
    projectImg: qurbanss,
    projectHeading: "কুরবানী প্রজেক্ট SSF - ২০২২",
    slug: "qurban-saba-sanabil",
    projectDetails: (
      <div>
        <p>
          ছেলেটি মাদ্রাসায় পড়ে। বাবা ও ছেলে এসেছে আমাদের গরুটা দেখাতে। বন্যায়
          তাদের ঘর ও পশু দুইটি ক্ষতিগ্রস্ত হয়েছে। এই গরুটা ইমারজেন্সী বিক্রি করা
          প্রয়োজন ছিল। ব্যাপারীরা ৫০ হাজাড় টাকা পর্যন্ত দাম করেছে। আমাদের কাছে
          ৫২ হাজাড়েই বিক্রি করতে চাচ্ছিল। আমরা বলেছি কুরবানী পর্যন্ত পালতে
          থাকেন। আমরা ৫৪ হাজাড়ে গরুটি কিনে নিলাম। বন্যার সুযোগে যেখানে গরু পানির
          দামে বিক্রি হয় সেখানে এটা ছিল তাদের ক্ষতিগুলো পুষিয়ে নেয়ার ক্ষুদ্র
          প্রচেষ্টা।
        </p>
        <p>
          খুব কষ্টের সাথে বলতে হয় গত বছরের আগের বছর যেখানে প্রায় ৬৭ টি পশু
          কুরবানি করেছিলাম সেখানে এ বছর এখন পর্যন্ত ৩টি গরু কেনার টাকা জমা
          হয়েছে। অথচ এ বছরে বন্যার্ত এলাকাগুলোতে পশুগুলো কিনে উনাদের গ্রামেই
          কুরবানি করতে পারাটাই দরকার ছিল।
        </p>

        <div>
          <p>আমাদের কুরবানি প্রজেক্ট আপনারা ৩ভাবে অংশ নিতে পারবেনঃ</p>
          <p>১. একক ভাবে </p>
          <p> ২. ভাগে </p>
          <p>
            ৩. যে কোন পরিমাণ নফল সাদকা দিয়ে (এই টাকা দিয়ে বন্যার্ত অঞ্চলে
            কুরবানি করা হবে ইনশাআল্লাহ){" "}
          </p>
        </div>

        <div>
          <p>একক ভাবে ও ভাগে কুরবানির প্যাকেজ সমূহ (বিকাশ খরচসহ):</p>
          <p>১. একটা ছোট সাইজের গরু/গাভী- ৪৫৫০০ টাকা </p>
          <p> ২. ছোট সাইজের গরুর প্রতি ভাগ- ৬৫০০ টাকা </p>
          <p>৩. মধ্যম সাইজের একটি গরু- ৫৯৫০০ টাকা</p>
          <p>৪. মধ্যম সাইজের গরুর প্রতিটি ভাগ- ৮৫৯০ টাকা</p>
          <p>৫. মধ্যম সাইজের একটি খাসি- ৯৫৯০ টাকা</p>
        </div>
        <p>
          এছাড়া যে কোন পরিমাণ নফল সাদাকা করতে পারবেন উপরের একাউন্ট নম্বরগুলোতে।
          আমাদের কুরবানির ফান্ডে যেটা ব্যবহার করা হবে সিলেট, কুড়িগ্রামের
          বন্যার্ত গ্রামগুলোতে ইনশাআল্লাহ।
        </p>

        <p className="fw-bold">নিয়মাবলী: </p>
        <p>
          - টাকা পাঠানোর পরে আমাদের পেইজের ইনবক্সে কোন নাম্বার থেকে কত টাকা
          পাঠিয়েছেন এবং কোন পশুর জন্য (ভাগে দিলে ভাগের সংখ্যা) সেটা উল্লেখ করে
          দেবেন। সেই সাথে আপনার মোবাইল নম্বরটিও ইনবক্সে দিয়ে দিবেন।
        </p>
        <p>
          - টাকা পাঠানোর পরে আপনার পশুর টোকেন নাম্বারের জন্য মেসেজ দিয়ে রাখুন।
          আগামী মাসের ৮ই জুলাই এর মধ্যে আপনাকে টোকেন দিয়ে দেয়া হবে ইনশাআল্লাহ।
          নফল সাদাকাহ পাঠালে টোকেন নম্বর লাগবে না।
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">টাকা পাঠানোর একাউন্ট নাম্বারঃ</h4>
        <p>bKash Personal: 01309-336883 </p>
        <p>Nagad Personal: 01309-336883</p>
        <p> Rocket personal: 01309-336883-8 </p>

        <h4 className="mb-4">ব্যাংক একাউন্ট নাম্বার ১ঃ </h4>
        <p>Bank: Islami Bank Bangladesh Ltd. </p>
        <p>A/C No(Current): 20507310100010403 </p>
        <p>A/C Name: Saba Sanabil Foundation</p>
        <p> Branch: Kalampur SME (Dhaka North) </p>

        <h4 className="mb-4">ব্যাংক একাউন্ট নাম্বার ২ঃ </h4>
        <p>Bank: Dutch Bangla Bank Ltd.</p>
        <p>A/C No(Current): 294.110.000.5513 </p>
        <p>A/C Name: Sab-a-Sanabil</p>
        <p> Branch: Lalmonirhat </p>
        <p>Routing: 090520466</p>

        <hr />
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/SabaSanabilFoundation/`,
    projectOrgName: "Sab'a Sanabil Foundation",
    projectOrg: { _ID: "sdqmeq001", name: "Sab'a Sanabil Foundation" },
    projectCreated: "July 2022",
    projectTag: "কুরবানী, Eid",
    projectLink:
      "https://www.facebook.com/SabaSanabilFoundation/photos/a.1146693308844740/2064873070360088",
  },

  {
    _ID: "sdqmeq002.1",
    projectImg: qurbanovijatrik,
    projectHeading: "কুরবানী প্রজেক্ট by অভিযাত্রিক",
    slug: "qurban-ovijatrik",
    projectDetails: (
      <div>
        <p>
          সারা বছরে যাদের এক কেজি গোশত কিনে খাওয়া কষ্ট হয়ে যায় তাদের জন্য
          অভিযাত্রিক এর এই ক্ষুদ্র প্রচেষ্টা। ঈদুল আযহার তিন দিনে আমরা এমন কিছু
          অসহায় পরিবারের মুখে আল্লাহর সন্তুষ্টির উদ্দেশ্যে হাসি ফোটাতে চাই।
        </p>
        <p>
          আমরা চাই একটা গ্রামের অসহায় কিছু পরিবারের ঈদের সকাল টা যেনো আর দশটা
          সকালের মত না হয়। ঈদের আনন্দে যেনো তারাও শরীক হতে পারে। ইনশাআল্লাহ
          অভিযাত্রিকের কোরবানির প্রজেক্টে যদি আপনি শরীক থাকেন তাহলে আপনার দেওয়া
          কোরবানির গোশত সেই সব মানুষদের মুখে হাসি ফোটাবে আর আপনার তাকওয়া আপনার
          আমলনামা কে ভারী করবে ইনশাআল্লাহ।
        </p>
        <p>অভিযাত্রিকের মাধ্যমে ১টি ছাগল কোরবানি করুন। </p>
        <p className="fw-bold">ছাগলের দামঃ ১০ হাজার টাকা (প্রসেসিং সহ) </p>
        <p>
          ছাগলের পুরো গোশত বিলিয়ে দেওয়া হবে অভাবী, গরীব, দুঃখী যারা কোরবানি দিতে
          পারেনি তাদের মাঝে ইনশাআল্লাহ।{" "}
        </p>
        <p>যোগাযোগঃ হোয়াটস এপ 01717017645</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">টাকা পাঠানোর একাউন্ট নাম্বারঃ</h4>
        <p>বিকাশ/নগদ: </p>
        <p>01717-017645</p>
        <p>01776-805250</p>
        <p>01720-803305</p>

        <h4 className="mb-4">ব্যাংক একাউন্ট নাম্বারঃ </h4>
        <p>Bank: Dutch Bangla Bank Ltd.</p>
        <p>A/C Name: Md Ariful Huq</p>
        <p>A/C No: 172.101.0017766 </p>
        <p> Branch: Dinajpur </p>

        <hr />
      </div>
    ),
    projectFbProfile: `https://sadaqahmadeeasy.com/org/ovijatrik`,
    projectOrgName: "অভিযাত্রিক",
    projectOrg: { _ID: "sdqmeq002", name: "অভিযাত্রিক" },
    projectCreated: "July 2022",
    projectTag: "কুরবানী, Eid",
    projectLink:
      "https://www.facebook.com/ovijatrik.dinajpur/photos/a.174873977714398/537275004807625/",
  },
];

export function getQurbanProjectList() {
  return QurbanProjectList;
}
