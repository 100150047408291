const RamadanProjects = [
  {
    _ID: "sdqmer001.1",
    projectImg: "",
    projectHeading: "ডেইলি ইফতার by Tanbir Ahmed",
    projectDetails: `আপনিও একজন এতিম অসহায়কে ইফতার করাতে পারেন।
আজকে আরেকটা এতিমখানায় ইফতারের ব্যবস্থা করা হবে ইনশাআল্লাহ। 
জনপ্রতি ১০০ টাকা।
রাঙামাটির দুর্গম পাহাড়ি এলাকার একটা এতিমখানায় গতকাল ইফতারের ব্যবস্থা করা হয়েছিল আলহামদুলিল্লাহ। `,
    projectContact: `বিকাশ: 01575-087680 || বিকাশ, নগদ, রকেটঃ 01818835477`,
    projectFbProfile: `https://fb.com/tanbir.ahmad.336`,
    projectOrgName: "Tanbir Ahmad",
    projectOrg: { _ID: "sdqmer001", name: "Tanbir Ahmad" },
    projectCreated: "February 2022",
    projectTag: "Ramadan, Zakat",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=504626547782441&id=100047052998545",
  },

  {
    _ID: "sdqmer003.1",
    projectImg: "",
    projectHeading: "সরোবরের ইফতার প্যাকেজ",
    projectDetails: (
      <div>
        আমরা ইফতার প্রজেক্ট শুরু করেছিলাম বেশ কিছুদিন আগে। দেখতে দেখতে রামাদান
        চলে এসেছে। আমাদের ইফতার প্যাকেজগুলোও ক্রেতাদের পক্ষ থেকে পৌঁছানো হয়েছে
        নির্দিষ্ট গন্তব্যে। ইফতার প্যাকেজ এর বিক্রি শেষ। যেহেতু আমাদের স্টকে আর
        অবশিষ্ট নেই, তাই চাহিদা সত্ত্বেও আমরা দিতে পারছি না বলে অত্যন্ত দুঃখিত।
        সর্বোপরি আমাদের ইফতার প্রজেক্টের প্রডাক্ট এবং সার্ভিস নিয়ে আপনাদের
        মতামত, পরামর্শ আশা করছি। এবং যেহেতু আমাদের সেলস শেষ তাই আপনাদেরকেও
        অনুরোধ করছি কোন ধরণের পেমেন্ট না করার জন্য।
        <p>
          আমাদের এই যাত্রায় সাথে থাকার জন্য আপনাদের সবাইকে ধন্যবাদ। আল্লাহ সকলের
          নিয়াতকে কবুল করুন।
        </p>
      </div>
    ),
    projectContact: "",
    projectFbProfile: `https://fb.com/sharif.abu.hayat`,
    projectOrgName: "সরোবর",
    projectOrg: { _ID: "sdqmer002", name: "সরোবর" },
    projectCreated: "March 2022",
    projectTag: "Ramadan, Iftar",
    projectLink: "https://www.facebook.com/shoroborbd/posts/2380961418706678",
  },

  {
    _ID: "sdqmer006.1",
    projectImg: "",
    projectHeading: "প্রতিদিন ইফতার বিতরণ",
    projectDetails: (
      <div>
        পবিত্র মাহে রমজানে যখন মাগরিবের আজানের ধ্বনি কানে ভেসে আসে তখন,
        নামি-দামি বাহারি আইটেমের ইফতার নিয়ে ব্যস্ত থাকি আমরা।
        <br />
        আমরা কি অনুভব করতে পারি, যাদের ঘরে ইফতার করার মত নূন্যতম খাবারটুকুও থাকে
        না, তাদের কাছে ইফতারের সময়টা কত কষ্টে কাটে? বাচ্চারা ইফতার পছন্দ করে।
        তাদের সামনে যখন কোনো কিছু উপস্থিত করা যায় না তখন মা বাবার চেহারা কতটা
        মলিন হয় আমরা কি বুঝতে পারি?
        <br />
        পুরো রমজানে আল মানাহিলের সময় কাটে এসব অসহায় রোজাদার মানুষকে নিয়ে।
        ফুটপাতে, পথেঘাটে, বস্তি এলাকায়, গরিব রিকশা ভ্যান চালক, বিভিন্ন কলোনিতে
        বসবাসকারী গার্মেন্টস কর্মীদের মাঝে প্রতিদিন ইফতার বিতরণ করে আল মানাহিল
        পরিবার। প্রতি বছরের ন্যায় এ বছরও এ কার্যক্রম চলবে ইনশাআল্লাহ।
        <br />
        একজন রোজাদারের ইফতার বাজেট ৭০ টাকা। প্রতিজনকে উন্নতমানের গোশত বা মুরগীর
        বিরিয়ানি সাথে ডিম, খেজুরসহ বিভিন্ন ফলমূল ও পানি দেওয়া হবে।
        <br />
        আমরা এমন কিছু ব্যক্তির সন্ধান করছি যারা তাদের বাজেট অনুযায়ী রোজাদারের
        জন্য ডিম, মাংস, ফল বা পানিও হলেও উপহার দিয়ে রোজার সমপরিমাণ সওয়াবের
        ভাগীদার হবেন। গরিব রোজাদারদের ইফতার করাতে চাইলে আমাদের ফেসবুক পেইজের
        ইনবক্সে যোগাযোগ করার অনুরোধ রইল।
      </div>
    ),
    projectContact: `সাদাকাহ পাঠাতে ফেসবুক পেইজে ইনবক্স করুন`,
    projectFbProfile: `https://www.fb.com/amwfborg`,
    projectOrgName: "আল মানাহিল -Al Manahil Foundation",
    projectOrg: { _ID: "sdqmer006", name: "Al Manahil Foundation" },
    projectCreated: "March 2022",
    projectTag: "ইফতার, 30days",
    projectLink: "https://www.facebook.com/amwfborg/videos/1324867314647228/",
  },
];

export function getRamadanProjects() {
  return RamadanProjects;
}
