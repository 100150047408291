import tarbiyahacad from "../img/tarbiyahacad.jpeg";

const LastTen = [
  {
    _ID: "sdqmet001.1",
    projectImg: tarbiyahacad,
    projectHeading: "তারবিয়াহ একাডেমি",
    slug: "tarbiyyah-academy",
    projectDetails: (
      <div>
        আসসালামু আলাইকুম ওয়া রহমাতুল্লাহি ওয়া বারকাতুহু
        <br />
        <br />
        <p>
          আমাদের তারবিয়াহ একাডেমি মূলত 'মারকাযুল উলূম আশ-শারইয়্যাহ ওয়া
          তারবিয়াতিল মুসলিমীন' এর শাখা কার্যক্রম। মারকাযের যেসব ছাত্ররা আবাসিক
          থেকে হিফয ইত্যাদি পড়াশোনা করে তাদের অধিকাংশই গরীব। যাদের খাবার, আবাসন,
          চিকিৎসা, কখনো পোশাক এমনকি শিক্ষা সফরের খরচও মারকায বহন করে। রমাদানের
          শেষ দশকে এমনিতেই দান করার গুরুত্বপূর্ণ সময়। মারকাযও সারা বছরের বার্ষিক
          খরচ রমাদানে সংগ্রহ করে থাকে। মারকাযের বহুমুখী খিদমতে আপনি নিজের
          যাকাত/সদকা/অনুদান দিয়ে অথবা পরিচিত কাউকে সাজেস্ট করেও সদকায়ে জারিয়াহর
          অংশী হতে পারেন।
        </p>
        <p>بارك الله فيكم! جزاكم الله احسن الجزاء!</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">মারকাযে অনুদান পাঠানোর মাধ্যম:</h4>
        <p>A/C Name: Ignite </p>
        <p>Bank A/C Number: 1532204553244001 </p>
        <p className="mb-4">
          {" "}
          Bank Name: BRAC Bank, Bijoy Nagar Branch, Dhaka{" "}
        </p>

        <hr />
        <p> bKash Number: 01965-159658 (Personal ) | সাধারণ অনুদানের জন্যে। </p>
        <p> bKash Number: 01747-031015 (Personal ) | শুধু যাকাত এর জন্যে। </p>
        <p> Nagad Number: 01810-113699 </p>
        <p> Rocket Number: 01965-1596583 </p>
        <p>(নগদ বা রকেটের মাধ্যমে যাকাত পাঠিয়ে থাকলে অবগত করতে হবে)</p>
        <span className="fw-normal">
          এছাড়া মারকাযের ব্যাংক হিসাবও আছে। মারকায সম্পর্কে আরো জানতে চাইলে
          ওয়েবসাইট ভিজিট করুন!
        </span>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/academytarbiyah/`,
    projectOrgName: "Tarbiyah academy",
    projectOrg: { _ID: "sdqmet001", name: "Tarbiyah academy" },
    projectCreated: "April 2022",
    projectTag: "Zakat, Sadaqah",
    projectLink: "https://www.markazululum.com/",
  },
];

export function getLastTen() {
  return LastTen;
}
