import * as organizationsAPI from "./OrgList";

import NolkupSdq3 from "../img/NolkupSdq3.jpeg";
import TanbirProj1lakh from "../img/TanbirProj1lakh.jpeg";
import TanbirProjVola from "../img/TanbirProjVola.jpeg";
import kaffaraTanbir from "../img/kaffaraTanbir.jpeg";
import masjidSabas from "../img/masjidSabas.jpeg";
import ramfit from "../img/ramfit.jpeg";
import sylhetRebuilt1 from "../img/sylhetRebuilt1.jpeg";
import tahsincancer from "../img/tahsincancer.jpeg";
import tahsinreport from "../img/tahsinreport.jpeg";
import helpscholar from "../img/helpscholar.jpeg";
import jumabarhelpcover from "../img/jumabarhelpcover.jpeg";

import jihadulsurgery from "../img/jihadulsurgery.jpeg";
import jihadulreport from "../img/jihadulreport.jpeg";

//last serial: 47

const projects = [
  {
    _ID: "sdqmet003.1",
    projectImg: masjidSabas,
    projectHeading: "সাব'আ সানাবিল মসজিদ কমপ্লেক্স",
    slug: "saba-sanabil-mosque",
    projectDetails: (
      <div>
        <h3>
          {" "}
          সাব'আ সানাবিল মসজিদ কমপ্লেক্স ও আমাদের একটা স্থায়ী অফিস ও দাওয়াহ
          সেন্টার কেন এত প্রয়োজন?
        </h3>

        <p>
          আমরা অনেকেই মসজিদ, মাদ্রাসা নির্মাণে অর্থ ব্যয় করতে চাই কিন্তু
          ম্যানেজমেন্ট এর পেছনে অর্থ করতে আমাদের ভীশন অনাগ্রহ। অথচ একটা গাড়ি
          চালাতে হলে এর তেলের খরচ আপনাকে বহন করতে হবে না হলে আপনি গাড়ি কিনলে
          গাড়ি দিয়ে কোন কাজ করতে পারবেন না। এই যে আপনাদের অর্থের মাধ্যমে সাব'আ
          সানাবিল ফাউন্ডেশন খাগড়াছড়ি, বান্দরবান রাঙ্গামাটি, কুড়িগ্রাম
          নীলফামারী সহ দেশের বিভিন্ন প্রত্যন্ত এলাকায় মসজিদ ভিত্তিক মাদ্রাসা,
          কমিউনিটি টিউবওয়েল, এতিম ছাত্রদের স্পনসর্শিপ সহ অভাবী মানুষদের সহায়তা
          করে যাচ্ছে তার জন্য একটা টিম দেশের বিভিন্ন প্রান্ত থেকে কাজ করে যাচ্ছে
          নিয়মিত আলহামদুলিল্লাহ। একটা জিনিস অনেকেই জানেন আমাদের এডমিন প্যানেলের
          ভাইরা দেশের বিভিন্ন প্রান্ত থেকে কাজ করে থাকেন এবং কেউ ফাউন্ডেশন থেকে
          একটা পয়সাও পারিশ্রমিক নেন না। এই ম্যানেজমেন্ট টিমকে সমন্বয় করতে
          আমাদের একটি স্থায়ী জায়গা প্রয়োজন যে জায়গা থেকে কেন্দ্র করে গড়ে
          উঠবে একটি মসজিদ কমপ্লেক্স ও দাওয়াহ সেন্টার। আমাদের একটি স্বপ্ন আছে, এই
          যে দেশের বিভিন্ন প্রান্তের আমাদের মসজিদভিত্তিক মাদ্রাসা গড়ে উঠছে সেই
          ছাত্রগুলো কে আমরা কোয়ালিটি এডুকেশন নিশ্চিত করতে আমরা একসময় আমাদের
          মসজিদ কমপ্লেক্স থেকে যোগ্য শিক্ষকদের মাধ্যমে প্রতি সপ্তাহে অনলাইনে
          কিছু ক্লাসের ব্যবস্থা করবো যাতে দেশের বিভিন্ন প্রান্তে ছড়িয়ে থাকা
          ছাত্র গুলো বিভিন্ন কুসংস্কার ও বিদাআত থেকে বেরিয়ে আসতে পারে ইন শা
          আল্লাহ।
        </p>
        <p>
          ইনশাআল্লাহ এই অফিস থেকেই আমাদের জেনারেল ও মাদ্রাসা পড়ুয়া
          ছাত্র-শিক্ষকদের জন্য মাদানী নেসাবের উপর একাডেমিক ভিডিও কনটেন্ট,
          বিভিন্ন টপিকের উপর ওস্তাদদের আলোচনা সহ সকল ধরনের ভিডিও কনটেন্ট
          প্রোডাকশন করা হবে ইনশাআল্লাহ। আল্লাহ চাইলে এ সকল কাজের প্রাণকেন্দ্র
          হতে যাচ্ছে আমাদের এই মসজিদ কমপ্লেক্স যেখানে থাকবে সাব'আ সানাবিল
          ফাউন্ডেশনের স্থায়ী কার্যালয়। কারণ আমরা বিশ্বাস করি ছড়িয়ে ছিটিয়ে কাজ
          করলে তা সাস্টেইনেবল হবার সম্ভাবনা কম, তাই দাওয়াহ সেন্টার ও মসজিদ
          কমপ্লেক্স কে কেন্দ্র করেই আমাদের স্থায়ী অফিসের পরিকল্পনা চলছে ইন শা
          আল্লাহ।
        </p>
        <p>
          আমাদের দাওয়াহ সেন্টারের ভাই বোনরা বিগত ৩ বছর যাবৎ অল্প অল্প সাদাকা জমা
          করে এই মসজিদ কমপ্লেক্স এর জমি ক্রয় বাবদ প্রায় ১৫ শতাংশ টাকা জমা
          করেছেন আলহামদুলিল্লাহ। আপনারা সবাই জানেন আমাদের কাজের পরিধি
          আলহামদুলিল্লাহ দিন দিন বাড়ছে, প্রতি মাসেই দাওয়াহ সেন্টারের মাধ্যমে
          কিছু অভাবী মানুষকে সহযোগিতা করা হয় তাই চাইলেও এই ভাইবোনদের সাদাকা আমরা
          সম্পূর্ণটা জমি ক্রয় বাবদ ব্যবহার করতে পারি না। আপনারা সবাই এই জমির
          অংশগ্রহণ না করলে আমাদের জন্য এই প্রজেক্ট সম্পন্ন করা অনেক কঠিন হয়ে
          যাবে।
        </p>

        <div>
          <p>
            যে চারটি উপায় আপনারা এই সাদকায়ে জারিয়া তে অংশগ্রহণ করতে পারবেন:
          </p>
          <p>
            ১. এক স্কয়ার ফিট জমি ক্রয়ের সাদাকা প্রদানের মাধ্যমে, প্রতি স্কয়ার
            ফিটে দাম আসবে ৮০০০ টাকা।{" "}
          </p>
          <p>
            ২. মারকাজ মসজিদ কমপ্লেক্স এর একজন প্রতিষ্ঠাতা সদস্য হবার মাধ্যমে।
            জমি ক্রয়ের জন্য কেউ ন্যূনতম ৫০ হাজার টাকা সাদাকা করলে আমরা তাকে
            মারকাজ মসজিদ কমপ্লেক্স এর একজন প্রতিষ্ঠাকালীন সদস্য বলে গণ্য করব।{" "}
          </p>
          <p>৩. যে কোন পরিমাণ সাদাকা প্রদানের মাধ্যমে।</p>
          <p>
            ৪. যেহেতু আমাদের এই প্রতিষ্ঠানে এমন কোন ব্যক্তি নেই যার একটি পোস্ট
            শেয়ারের মাধ্যমে অনেক লোক জানতে পারবে তাই আপনাকে অনুরোধ করব নিজের
            ওয়ালে বা এই পোস্ট শেয়ার করে আপনার পরিচিতদের কে সদকায়ে জারিয়া
            প্রজেক্টে যুক্ত করতে পারেন ইনশাআল্লাহ।
          </p>
        </div>
        <p>
          সব শেষে, রমাদানের এই শেষ দশটি রাতে আমরা সবাই চেষ্টা করি যে যার
          সাধ্যমতো সর্বোচ্চ ভালো সাদাকা করার জন্য। আল্লাহ চাইলে এই দশটি রাতের
          মধ্যে একটি রাত যদি লাইলাতুল কদর হয় আর সে রাতে যদি আপনি একটি টাকাও
          সাদাকা করেন তাহলে সেই সাদাকা হয়ে যেতে পারে হাজাড় মাস বা ৮৩ বছর সাদাকা
          করার চাইতে উত্তম ইনশাআল্লাহ।
        </p>
        <p className="fw-bold">
          আমাদের জমি ক্রয়ের প্রজেক্টে যুক্ত হতে নিচের একাউন্ট গুলোতে সাদাকা
          পাঠাতে পারবেন আগামী ৩০ রমাদান রাত বারোটা পর্যন্ত ইনশাআল্লাহ।
        </p>
        <p>
          নোট: আমাদের এই প্রোজেক্টের জন্য ৪০শতাংশ টাকা উঠে আসলে আমরা মসজিদ
          কমপ্লেক্সের জায়গা নির্বাচন সংক্রান্ত কাজ শুরু করব ইনশাআল্লাহ। যখনই
          জায়গা নির্বাচন করা হবে আপনাদেরকে আমাদের এই পেইজের মাধ্যমে আপডেট
          জানানো হবে ইনশাআল্লাহ।{" "}
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">যাকাত ফান্ডের একাউন্ট নাম্বারঃ</h4>
        <p>bKash Personal: 01309-336883 </p>
        <p> Rocket personal: 01309-336883-8 </p>
        <p>Nagad Personal: 01309-336883</p>

        <hr />

        <p>Bank: Islami Bank Bangladesh Ltd. </p>
        <p>A/C No(Current): 20507310100010403 </p>
        <p>A/C Name: Saba Sanabil Foundation</p>
        <p> Branch: Kalampur SME (Dhaka North) </p>

        <hr />

        <p>Bank: Dutch Bangla Bank Ltd. </p>
        <p>A/C Name: Sab-a-Sanabil </p>
        <p>A/C No: 294.110.000.5513</p>
        <p> Branch: Lalmonirhat </p>
        <p> Routing: 090520466</p>
        <hr />
        <p>PayPal: sabasanabil.org@gmail.com</p>

        <hr />
        <p>
          "যারা আল্লাহর পথে ব্যয় করে, তাদের উপমা একটি শস্য-বীজের মত, যা থেকে
          সাতটি শীষ জন্মে, প্রতিটি শীষে থাকে একশত শস্য-দানা। আর আল্লাহ যার জন্য
          ইচ্ছা বহুগুণে বৃদ্ধি করে দেন। আল্লাহ মহাদানশীল, মহাজ্ঞানী।" সূরা
          বাকারাহ- ২৬১
        </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/SabaSanabilFoundation/`,
    projectOrgName: "Sab'a Sanabil Foundation",
    projectOrg: { _ID: "sdqmet003", name: "Sab'a Sanabil Foundation" },
    projectCreated: "April 2022",
    projectTag: "Zakat, Sadaqah",
    projectLink:
      "https://www.facebook.com/SabaSanabilFoundation/posts/2014701378710591",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },
  {
    _ID: "sdqmet043.1",
    projectImg: jumabarhelpcover,
    projectHeading: "শুক্রবারের সাহায্য",
    slug: "juma-help-ovijatrik",
    projectDetails: (
      <div>
        <h3>
          {" "}
          ঠিক করেছি এখন থেকে প্রতি বৃহস্পতিবার রাতে একজন করে অসহায় মানুষের অভাব
          পূরণের জন্য সাহায্য চেয়ে পোস্ট করবো এবং পরদিন শুক্রবার পর্যন্ত টাকা
          সংগ্রহ করবো। আপনাদের ওছিলায় যদি আল্লাহ কবুল করেন এবং তার জন্য
          প্রয়োজনীয় ফান্ড সংগ্রহ হয়ে যায় তাহলে শনিবার দিন সেই মানুষটার অভাব পূরণ
          করে দিয়ে তার হাসিমাখা ছবি আপনাদের সাথে শেয়ার করবো ইনশাআল্লাহ।
        </h3>

        <p>এই প্রজেক্টার নাম দিয়েছি "শুক্রবারের সাহায্য"</p>
        <p>
          আল্লাহ তৌফিক দিলে আগামী বৃহস্পতিবার রাতে আবারো উপস্থাপন করবো অন্য কোন
          অসহায় মানুষের গল্প। এভাবে প্রতি শুক্রবার আপনারা সাধ্যমতো এগিয়ে আসলে
          ইনশাআল্লাহ প্রতি শনিবার অভিযাত্রিক এর মাধ্যমে আমরা এমন অসহায় মানুষদের
          মুখে হাসি ফোটাতে পারবো আশা করি। আপনারা যাকাতের টাকাও দিতে পারেন।
          আল্লাহ আমাদের সকলের প্রচেষ্টা কবুল করুন। উত্তম যাযাখায়ের দান করুন।
          আমীন।
        </p>
        <p>প্রজেক্ট চেক করতে ক্লিক করুনঃ </p>
        <div
          style={{ height: "30vh", overflowY: "scroll" }}
          className="border shadow-sm p-3"
        >
          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/photos/a.174873977714398/621030503098741/"
            target="__blank"
            className="mb-3 text-primary"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ১১
          </a>
          <br />
          <a
            href="https://www.facebook.com/md.arifulhuq/posts/pfbid02n1pD8sjmuDDE4KFiv4oPiAun2dLjZCnHa7bRxfYaqxbmzYm9dshiiY11jkaYJqTal"
            target="__blank"
            className="mb-3 text-primary"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ১০
          </a>
          <br />
          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/photos/a.174873977714398/615718346963290/"
            target="__blank"
            className="mb-3 text-primary"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৯
          </a>
          <br />
          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/videos/400298612314975/"
            target="__blank"
            className="mb-3 text-primary"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৮
          </a>
          <br />

          <a
            href="https://www.facebook.com/md.arifulhuq/posts/pfbid0noLxVG6Eo8Z4jncdP7Kp6HnKMpiGdsV9vwa9ntRCinzX16xwZm5oGVNCpDVrbGzEl"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৭
          </a>
          <br />
          <a
            href="https://www.facebook.com/md.arifulhuq/posts/pfbid02pYuU7QLaBxQEeNTs8oHqxHzy1VxAjTvsXxSX7s69kVKkJhmsiT2efNCWb64aD8aql"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৬
          </a>
          <br />
          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/photos/a.174873977714398/593999309135194/"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৫
          </a>
          <br />
          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/posts/pfbid0jc1Q36ntEv1yjMgQpXjfETfRuv3Sfz6jN6o7a4QnYmqpTU6oaeFpJFBwtpe5USrkl"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৪
          </a>
          <br />
          <a
            href="https://www.facebook.com/117227633479033/posts/586784113190047"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ৩
          </a>
          <br />

          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/posts/pfbid02sUwaDqhm73UCXma8mT3KtFQArQbaVa9Vf2xPnLSaW52aoqiXRJSy5F1JXb3UW862l"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ২
          </a>
          <br />
          <a
            href="https://www.facebook.com/ovijatrik.dinajpur/posts/pfbid026MPoAJDVwjvNRXV4gmBuFXwnxiuLLZ6X2u2WUdxp82nTh6Mng9xxBLDRbutKWX52l"
            target="__blank"
            className="mb-3 text-success"
          >
            শুক্রবারের সাহায্য প্রজেক্ট - ১
          </a>
        </div>
        <span style={{ fontSize: "16px", marginTop: "2rem" }}>
          সব দেখতে Scroll করুন
        </span>

        <p className="mt-4">
          শুক্রবারের
          <br />
          🔷 সাহায্য প্রজেক্ট ১০ এর সাহায্য ছিলো = ৭৫,০০০ /-
          <br />
          🔷 সাহায্য প্রজেক্ট ০৯ এর সাহায্য ছিলো = ১ লক্ষ ৫৩ হাজার /-
          <br />
          🔷 সাহায্য প্রজেক্ট ০৮ এর সাহায্য ছিলো = ১ লক্ষ /-
          <br />
          🔷 সাহায্য প্রজেক্ট ০৭ এর সাহায্য ছিলো = ৪ লক্ষ ৩৭ হাজার /-
          <br />
          🔷 সাহায্য প্রজেক্ট ০৬ এর সাহায্য ছিলো = ২ লক্ষ ১৫ হাজার /-
          (এক্সিডেন্টে পংগুত্ব বরণ করা বিধবা বোনের হাতে তুলে দেওয়া হলো)
          <br />
          🔷 সাহায্য প্রজেক্ট ০৫ এর সাহায্য ছিলো = মোট ১২টি অসহায় পরিবার কে
          ৮,৫০০ টাকা সমমূল্যের ১২টি টিউবওয়েল, টিউবওয়েল এর যাবতীয় সরঞ্জাম এবং
          মেঝে পাকা করার জন্য নগদ ৫,০০০/- করে প্রতিটি পরিবারের হাতে দেওয়া হয়েছে।
          <br />
          🔷 সাহায্য প্রজেক্ট ০৪ এর সাহায্য ছিলো = ৬৭,০০০/- ( দশটি সেলাই মেশিন
          বিতরণ)
          <br />
          🔷 সাহায্য প্রজেক্ট ০৩ এর সাহায্য ছিলো = ৬৬,০০০/- (ঘর নির্মাণ)
          <br />
          🔷 সাহায্য প্রজেক্ট ০২ এর সাহায্য ছিলো = ৪৯,০০০/- ( নতুন ভ্যান ক্রয়)
          <br />
          🔷 সাহায্য প্রজেক্ট ০১ এর সাহায্য ছিলো = ২৪,০০০/- ( চার্জার ভ্যানের ৪
          টি ব্যাটারি ক্রয়)
          <br />
          🔷 আল্লাহর ইচ্ছায় আপনাদের সহযোগিতায় মোট ২,০৬,০০০/- টাকার সাহায্য করা
          হয়েছে গত ০৪ টি শুক্রবারে, আলহামদুলিল্লাহ।
        </p>
        <p>
          {" "}
          আপনারা শুক্রবারের সাহায্যের জন্য আমাদের বিকাশ/নগদ নাম্বারটি সেভ করে
          রাখতে পারেন। প্রিয় নাম্বার হিসাবে সেভ করলে টাকা পাঠাতে সেন্ড মানি ফি
          লাগবে না ইনশাআল্লাহ।
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">বিকাশ / নগদঃ 01720803305</h4>
        <p>
          রেফারেন্সঃ Friday Project (এখানে প্রজেক্ট সিরিয়াল যুক্ত হবে) - SME
        </p>
        <p>যেমনঃ Friday Project 05 - SME</p>

        <p>
          যে কোন প্রকার পরামর্শ ও মতামত জানাতে হোয়াটসঅ্যাপ করুনঃ +880
          1717-017645{" "}
        </p>

        <hr />
        <p>
          "যারা আল্লাহর পথে ব্যয় করে, তাদের উপমা একটি শস্য-বীজের মত, যা থেকে
          সাতটি শীষ জন্মে, প্রতিটি শীষে থাকে একশত শস্য-দানা। আর আল্লাহ যার জন্য
          ইচ্ছা বহুগুণে বৃদ্ধি করে দেন। আল্লাহ মহাদানশীল, মহাজ্ঞানী।" সূরা
          বাকারাহ- ২৬১
        </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/md.arifulhuq`,
    projectOrgName: "অভিযাত্রিক সমাজ কল্যাণ সংস্থা",
    projectOrg: { _ID: "sdqmet043", name: "অভিযাত্রিক সমাজ কল্যাণ সংস্থা" },
    projectCreated: "Sep 2022",
    projectTag: "Every Juma, Sadaqah",
    projectLink: "https://www.facebook.com/ovijatrik.dinajpur/",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqme0046.1",
  //    projectImg: jihadulsurgery,
  //    projectHeading: "১৪ মাস বয়সী জিহাদের হার্টে ছিদ্র",
  //    slug: "jihadul-surgery",
  //    projectDetails: (
  //      <div>
  //        <h3 className="mb-5">Latest update: আরো প্রায় ২ লক্ষ টাকা প্রয়োজন</h3>
  //        <h3 className="mb-4">
  //          আসসালামুআলাইকুম। আমার কাছের আত্মীয় খুব ছোটকাল থেকে এতিম। ছোটকালে বাবা
  //          মাকে পায়নি। আত্মীয়দের কাছে বড় হয়। বিয়ের পর তার প্রথম বাচ্চা মারা যায় ২
  //          বছর আগে। এখন ২য় বাচ্চা ১৪ মাস বয়সী জিহাদুল ইসলাম খুব অসুস্থ। ওর হার্টে
  //          ছিদ্র ও রক্তনালীর সমস্যা। এখন নিজের বাচ্চার আশা নিয়ে বেচে থাকায় কঠিন
  //          পরীক্ষায় পড়েছে। খুব দ্রুত সার্জারি করতে হবে। গতকাল অর্থাৎ (১৮/১০/২০২২)
  //          ন্যাশনাল হার্ট ফাউন্ডেশন, ঢাকাতে ভর্তি করানো হয়েছে। কয়েকদিন
  //          পর্যবেক্ষণে রেখে তারপর সার্জারি করা হবে। সার্জারির খরচ হবে প্রায় ৪
  //          লক্ষ টাকা।
  //        </h3>

  //        <p>
  //          জিহাদুল ইসলামের পরিবার অর্থনৈতিক ভাবে অসচ্ছল। তার পিতার আয় খুবই সীমিত
  //          যেহেতু দিনে আনে দিনে খেয়ে চলে, কখনো গাড়ি চালিয়ে বা কখনো কোন কাজে খেটে।
  //          এত টাকার অর্ধেক তার বছরেও আয় হয়না। তাই তাদের সাহস ও নাই যে অপারেশন
  //          করার জন্য কোন ডাক্তারের কাছে যাবে। আত্মীয় স্বজনদের ধরে প্রায় ২ মাস ধরে
  //          চট্টগ্রাম মেডিকেল হাঁসপাতাল, এভারকেয়ার হাঁসপাতাল এবং ন্যাশনাল হাট
  //          ফাঊণ্ডেশনে সব জায়গায় দোরাদোরি করে বেশ টাকাও খরচ হয়ে গিয়েছে ধার কর্যও
  //          হয়ে গেছে। বলে রাখা ভাল বাংলাদেশে বাচ্চার হার্ট ছিদ্রের অপারেশন করেন
  //          এমন ডাক্তার পাওয়া সহজ নয়। যিনি করতে পারবেন তাকেও ভাগ্যক্রমে দেখাতে
  //          পেরেছেন, তিনি অপারেশন করতে রাজি হয়েছেন। এর জন্য খরচ ৪ লক্ষ টাকা,
  //          একমাত্র আল্লাহর উপর ভরসা করে কিন্তু আল্লাহর ইচ্ছায়, আপনাদের সাহায্যে
  //          এই অপারেশন সম্ভব হতে পারে।
  //        </p>
  //        <img src={jihadulreport} alt="tahsinreport" className="w-75 mb-5" />
  //        <p>
  //          আপনারা কি অনুগ্রহপূর্বক আপনাদের সমর্থ অনুযায়ী জিহাদুল ইসলামের সাহায্যে
  //          এগিয়ে আসবেন? সম্মানিত পাঠক,আসুননা! এই মহা করুণার শুকরিয়া হিসেবে একজন
  //          বিপন্ন এতিম বোনের বাচ্চার সাহায্যে আমরা সবাই মিলে সর্বোচ্চ আন্তরিকতার
  //          সাথে এগিয়ে আসি। আমাদের সম্মিলিত প্রচেষ্টায় জিহাদ বেঁচে থাকুক। ওর মুখে
  //          আবারো হাসি ফুটুক। আল্লাহ তাআলা জিহাদকে বাঁচানোর চেষ্টার উছিলায় আল্লাহ
  //          আমাদেরকে জাহান্নামের আগুন থেকে বাঁচিয়ে দিক। এখানে মেডিকেল রিপোর্ট ও
  //          কাগজপত্র এটাচ করে দেওয়া হয়েছে। কেউ যাচাই বা দেখতে চাইলে রোগী বর্তমানে
  //          ন্যাশনাল হার্ট ফাউন্ডেশনে ভর্তি আছে সেখানে এসেও দেখে যেতে পারবে।
  //        </p>

  //        <p>
  //          অপারেশনের সম্ভাব্য সময়: ১ সপ্তাহ (পর্যবেক্ষনের উপর নির্ভরশীল, সময় কম
  //          লাগতে পারে ) <br />
  //          বাচ্চার বা রোগীর নাম - মোহাম্মদ জিহাদুল ইসলাম <br /> পিতা - আনোয়ার
  //          সাদেক <br /> মাতা - রেহেনা আকতার <br /> ঠিকানাঃ চকরিয়া, কক্সবাজার
  //        </p>

  //        <h4>
  //          প্রজেক্টটিতে যাকাত দেয়া যাবে। সেক্ষেত্রে রেফারেন্সে "Zakat" উল্লেখ করে
  //          দিতে হবে।{" "}
  //        </h4>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">সহযোগিতার জন্যঃ </h4>
  //        <p>বিকাশ (পার্সোনাল): +8801719260798</p>
  //        <p>রেফারেন্স এ "Zihadul Islam - sadaqahmadeeasy” লিখে দিবেন অবশ্যই।</p>
  //        <p className="mt-5">DBBL ব্যাংক একাউন্টঃ</p>
  //        <p>A/C Name: Khusnur Alam Siddiki </p>
  //        <p>A/C No: 129.105.14928</p>
  //        <p>Bank: Dutch Bangla Bank Limited </p>
  //        <p> Branch: O.R Nizam Road </p>
  //        <p className="mt-5">IBBL ব্যাংক একাউন্টঃ</p>
  //        <p>A/C Name: Shah Alam Siddque </p>
  //        <p>A/C No: 20503040201007811</p>
  //        <p>Bank: Islami Bank Bangladesh Limited </p>
  //        <p> Branch: O.R Nizam Road </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/brightheaven.riaad`,
  //    projectOrgName: "Khushnur Alam Siddiquee",
  //    projectOrg: { _ID: "sdqme0046", name: "Khushnur Alam Siddiquee" },
  //    projectCreated: "Oct 2022",
  //    projectTag: "শিশু, Surgery",
  //    projectLink:
  //      "https://www.facebook.com/brightheaven.riaad/posts/pfbid0w1BQL3hu8pWqCeiJFuHYDd2wWsWDqcuyPZPxgnGuKkEkYQdqFRgiu9zHKbq89gjEl",
  //    get getURL() {
  //      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
  //    },
  //  },

  {
    _ID: "sdqme0047.1",
    projectImg: helpscholar,
    projectHeading: "মসজিদে যাওয়ার পথে ইমামের",
    slug: "help-scholar",
    projectDetails: (
      <div>
        <h3 className="mb-4">
          একজন আলিম যত কঠিন পরিস্থিতিই হোক, মুখ ফুটে কারও কাছে কিছু চাইতে পারেন
          না। ইলমি গায়রত তাকে হাত পাতার সাহস দেয় না। আমাদের এবারের প্রজেক্ট এমন
          একজন আলিমের জন্য। মসজিদের ইমামতি আর মাদরাসার বেতন দিয়ে তার দিন কেটে
          যাচ্ছিল। ঘরে চারজন মেয়ে, কোনো ছেলে নেই। বাড়ি থেকে সাইকেলে মসজিদে
          যাওয়ার পথে পরিবহনের আঘাতে মারাত্মকভাবে আহত হন। দুমড়ে মুচড়ে যায় দু'পা।
          একেবারেই অচল হয়ে পড়েন। এরমাঝে ছুটে যায় ইমাতমতি ও মাদরাসার চাকরি।{" "}
        </h3>

        <p>
          পরিবারের একমাত্র উপার্জনক্ষম মানুষটি বিছানাবন্দি হয়ে পড়ায় পুরো পরিবার
          এখন মারাত্মক কঠিন পরিস্থিতিতে দিন কাটাচ্ছে। চিকিৎসা ও পরিবারের খরচ
          সামলাতে না পেরে তিনি অসহায় হয়ে আমাদেরকে স্মরণ করেছেন৷
        </p>

        <p>
          ঋণ ও আত্মীয়স্বজনের সহায়তায় অসুস্থতার মধ্যেই এক মেয়েকে বিবাহ দিয়েছেন।
          এক মেয়ে এখনই বিয়ের উপযুক্ত। তার দেওয়া তথ্যমতে এর মাঝেই ১,৫০০০০+ (
          একলক্ষ পঞ্চাশ হাজার+) শুধু চিকিৎসা বাবদ খরচ গিয়েছে। বর্তমানে
          প্রতিদিনের ঔষধ খরচ প্রায় একহাজার (১০০০)টাকা। মসজিদ কমিটি প্রাথমিকভাবে
          কিছু সহায়তা করলেও প্রয়োজনের তুলনায় তা খুবই কম। তার সার্বিক কাগজপত্র
          যাচাই করে আমরা তথ্য সঠিক বলে পেয়েছি।
        </p>

        <p>
          পরিবারের খরচ চালানোর মত তিনি ছাড়া আর কেউ নেই। আরও বিস্তারিত জানতে তার
          কাছ থেকে ফোন করে অথবা দেখা করে জেনে নেওয়া যেতে পারে। তার বর্তমান ঋণ ও
          সুস্থ হওয়ার আগ পর্যন্ত চিকিৎসা ও পরিবারের খরচাদির জন্য আমরা এই
          প্রজেক্টে ২,০০০০০ ( দু' লক্ষ) টাকার একটা বাজেট ধরেছি। সবাই
          স্বতঃস্ফূর্তভাবে অংশগ্রহণ করে পরিবারটিকে বিপদ হতে রক্ষা করুন। আল্লাহ
          তায়ালা সবার দান ও সদকাকে কবুল করে নেন।
        </p>
        <p>
          বি.দ্র ( এখানে সাহায্যপ্রার্থীর নম্বরটি দেওয়া জরুরী ছিল, কিন্তু বিশেষ
          বিবেচনায় তার নাম ও নম্বর উল্লেখ করতে পারছি না। কেউ যদি প্রয়োজন মনে
          করেন, উপরের নম্বরে কল করে সাহায্যপ্রার্থীর ঠিকানা ও নম্বর সংগ্রহ করে
          নিবেন ইনশাআল্লাহ।)
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">সহযোগিতার জন্যঃ </h4>
        <p>বিকাশ, নগদ, রকেট: 01317324294 (গ্রাহকের নামঃ জমির মাসরুর)</p>
        <p>Reference: SME-HELP-ALIM</p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/jomir071`,
    projectOrgName: "জমির মাসরূর",
    projectOrg: { _ID: "sdqme0047", name: "জমির মাসরূর" },
    projectCreated: "Oct 2022",
    projectTag: "আলিম, Accident",
    projectLink:
      "https://www.facebook.com/jomir071/posts/pfbid0qHJEP8wX6F3BA4FnDyVDiTJDXTNTWuTRboXtd48QZcFAjQP93keMXSj7tLDTKGejl",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  {
    _ID: "sdqme0035.1",
    projectImg: sylhetRebuilt1,
    projectHeading: "শ্রীমতি নাদইর সাহায্যে এগিয়ে আসুন",
    slug: "sylhet-rebuilt-1",
    projectDetails: (
      <div>
        <h3 className="mb-4">
          সিলেটে ২০২২ সালের অস্বাভাবিক বন্যার কারণে আপনারা সকলেই জানেন অসংখ্য
          পরিবার ক্ষতিগ্রস্থ হয়েছে। আমরা চাইলে সব পরিবারের তথ্য একসাথে একত্র
          করতে পারছি না। চেষ্টা শুরু করেছি ক্ষতিগ্রস্থ কিছু পরিবারের প্রাথমিক
          তথ্য সংগ্রহ করতে যাতে আমাদের সাইটের ভিজিটরদের জন্য সহজ হয় তাদের খুঁজে
          পেতে। এরই পরিপ্রেক্ষিতে আমরা গোয়াইনঘাট উপজলোর একটি পরিবারের সন্ধান
          পেয়েছি যার প্রাথমিক তথ্য নিচে উল্লেখ করা হল।
        </h3>

        <p>
          পরিবারের সম্মানিত প্রতিনিধি (মহিলা): শ্রীমতি নাদই
          <br />
          গ্রাম: ফুলরেগ্রাম
          <br />
          পোস্ট: লাফনাউট
          <br />
          উপজলো: গোয়াইনঘাট, সিলেট
        </p>

        <p>
          নোট: যারা সিলেটে বন্যা কবলিত অঞ্চলগুলোতে ত্রাণ বিতরণে সক্রিয়ভাবে কাজ
          করেছেন, প্রাথমিকভাবে আমরা তাদের কাছ থেকেই তথ্য সংগ্রহ করছি। আমাদের
          চেষ্টা থাকবে অন্তন্ত একটি পরিবার হলেও আমাদের প্ল্যাটফর্মের মাধ্যমে
          যাতে উপকৃত হোন সে-ই লক্ষ্যে কাজ করার।
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">সাহায্য পাঠানোর জন্যঃ</h4>
        <p>বিকাশ, নগদ, রকেট: 01717566822</p>
        <b>টাকা প্রেরণ করলে দয়া করে কল করে জানাবেন৷</b>

        <p className="mt-4">
          যে কোন যোগাযোগ: 01715336433 - নাম্বারটি উক্ত পরিবারের পাশের বাড়ির।
          যোগাযোগ করলে অবশ্যই শ্রীমতি নাদই পরিবারের সাথে যোগাযোগ করতে চাচ্ছেন তা
          জানানোর অনুরোধ করা হল।
        </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/nana.nani.73157`,
    projectOrgName: "Numan Ahmed",
    projectOrg: { _ID: "sdqme0035", name: "Numan Ahmed" },
    projectCreated: "June 2022",
    projectTag: "Sylhet, Resettlement",
    projectLink: "https://www.facebook.com/sadaqahmadeeasy",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  {
    _ID: "sdqme0039.1",
    projectImg: tahsincancer,
    projectHeading: "ব্লাডক্যান্সারে আক্রান্ত তাহসীন",
    slug: "tahsin-help",
    projectDetails: (
      <div>
        <h3 className="mb-4">
          শিশু তাহসীনের গতবছরের ঈদটা কেটেছিল পরম খুশি আর আনন্দে। তার ছোট্টমনের
          চাওয়াগুলো, তার ছোট ছোট আবদারগুলো খুব সহজেই পূরণ করেছিলেন তার বাবা।
        </h3>

        <p>
          কিন্তু এবারের পরিস্থিতি ভিন্ন। একেবারেই ভিন্ন। এবার তার একটাই আবদার।
          এই সুন্দর পৃথিবীতে মা-বাবা, ভাইকে নিয়ে সে আরো কিছুদিন বাঁচতে চাওয়া।
          ব্লাডক্যান্সারে আক্রান্ত তাহসীনের এই আবদার পূরণ করা তার বিপন্ন আলেম
          পিতার পক্ষে প্রায় অসম্ভব এর মতই।
        </p>

        <p>
          গত ১৫ই জুন ২২ খ্রি. বিএসএমএমইউতে (সাবেক পিজি হাসপাতাল) আট বছর বয়সী
          তাহসীনের দেহে মরণব্যাধি ব্লাডক্যান্সার ধরা পড়ে। বর্তমানে সে বিশিষ্ট
          হেমাটোলজিস্ট প্রফেসর আনোয়ারুল করিমের অধীনে বিএসএমএমইউ এর D ব্লকের
          হেমাটোলজি বিভাগে চিকিৎসাধীন রয়েছে। ডাক্তারদের ভাষ্যমতে,সঠিকভাবে
          চিকিৎসা চালিয়ে যেতে পারলে সুস্থ্য হওয়া সম্ভব এবং ৬ সেশন কেমোথেরাপি সহ
          পুরো চিকিৎসা চালিয়ে যেতে ১৫ থেকে ১৬ লাখ টাকার প্রয়োজন। কিন্তু অত্যন্ত
          দুঃখজনক বিষয় হচ্ছে, ইতোমধ্যেই লণ্ডভণ্ড হয়ে গেছে তাহসীনের বাবার আর্থিক
          সামর্থ্য।
        </p>

        <img src={tahsinreport} alt="tahsinreport" className="w-75 mb-5" />

        <p>
          মুসাপুরের একটি মাসজিদে জুম'আ পড়িয়ে আর সেনবাগের সেবারহাটে হোমিও
          প্র‍্যাক্টিস করে যিনি আত্মসম্মান নিয়ে জীবিকা নির্বাহ করছিলেন, আজকে
          সন্তানের চিকিৎসার ব্যস্ততায় একদিকে ইমকাম বন্ধ আরেকদিকে অত্যন্ত
          ব্যয়বহুল মেডিকেল খরচ, সবমিলিয়ে এক বিভীষিকাময় পরিস্থিতির মধ্য দিয়ে
          যাচ্ছেন।
        </p>

        <p>
          বাস্তবজীবনে ইসলামের অনুসারী এই মানুষটি তবুও সবর ও সন্তুষ্টির সাথে
          কলিজার টুকরা সন্তানকে বাঁচাতে প্রাণপণ চেষ্টা চালিয়ে যাচ্ছেন। হোমিও
          দোকানটা বিক্রি করতে চেয়েও পারছেননা। হাওলাত- বরাতে ও কুলোচ্ছেনা। আবার
          নিজের স্বভাবের বিরুদ্ধে গিয়ে কারো কাছে চাইতেও পারছেননা।{" "}
        </p>
        <p>
          বিষয়টা নিয়ে যখন উনার সাথে কথা হচ্ছিল তখন বারবার তিনি বারবার বাকরূদ্ধ
          হয়ে পড়ছিলেন। কী এক কল্পনাতীত পরীক্ষায় তিনি পড়েছেন! আমি তাঁর কষ্ট অনুভব
          করার চেষ্টা করলাম।
        </p>
        <p>
          আমি উপলব্ধি করলাম, আল্লাহ তায়ালা এমন দিশাহীন বিপদে আমাকে - আপনাকে বা
          আমাদের কলিজার টুকরা সন্তানদেরকে ও ফেলতে পারতেন। কিন্তু অসীম এক করুণায়
          তিনি আমাদের হেফাজত করছেন।{" "}
        </p>
        <p>
          সম্মানিত পাঠক,আসুননা! এই মহা করুণার শুকরিয়া হিসেবে একজন বিপন্ন আলেমের
          সাহায্যে আমরা সবাই মিলে সর্বোচ্চ আন্তরিকতার সাথে এগিয়ে আসি। সীতাকুন্ডু
          আর সিলেটবাসীর বিপদে ঝাঁপিয়ে পড়ার মত আরো একটি সুন্দর উদাহরণ তৈরি করি।
        </p>
        <p>
          আমাদের সম্মিলিত প্রচেষ্টায় তাহসীন বেঁচে থাকুক। ওর মুখে আবারো হাসি
          ফুটুক। আর এমনোতো হতে পারে, তাহসীনকে বাঁচানোর চেষ্টার উছিলায় আল্লাহ
          আমাদেরকে জাহান্নামের আগুন থেকে বাঁচিয়ে দিবেন।{" "}
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">সহযোগিতার জন্যঃ </h4>
        <p>বিকাশ (পার্সোনাল): 01977102913, 01836062305, 01813059992</p>
        <p>নগদ: 01977102913</p>
        <p className="mt-5">A/C Name: Mohammed Golam kabeer</p>
        <p>A/C No(Current): 2050 295020 10420 11 </p>
        <p>Bank: Islami Bank Bangladesh Ltd. </p>
        <p> Branch: Dagonbhuian </p>
        <p> District: Feni </p>
        <b>চাইলে রেফারেন্সে "Asif Mahmud" নাম ব্যবহার করতে পারেন। </b>

        <p className="mt-4">
          সার্বিক যোগাযোগ: মাওলানা সামসুদ্দীন।
          <br />
          সেবারহাট হোমিও হল। স্কুল মার্কেট, সেবারহাট, সেনবাগ, নোয়াখালী।
          <br />
          01855391984
        </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/asif.mahmud.lekhok`,
    projectOrgName: "Asif Mahmud",
    projectOrg: { _ID: "sdqme0039", name: "Asif Mahmud" },
    projectCreated: "July 2022",
    projectTag: "শিশু, Cancer",
    projectLink:
      "https://www.facebook.com/photo?fbid=3297049540543244&set=pcb.3297049627209902",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqme0041.1",
  //    projectImg: sjscover,
  //    projectHeading: "স্টিভেন্স জনসন সিনড্রোমে আক্রান্ত",
  //    slug: "sjs-help",
  //    projectDetails: (
  //      <div>
  //        <h3 className="mb-4">
  //          আসসালামু আলাইকুম, রাসুল সাল্লাল্লাহু আলাইহি ওয়া সাল্লাম বলেছেন, الله
  //          في عون العبد ما كان العبد في عون أخيه আল্লাহ্ সুবহানাহু তাআলা বান্দার
  //          ততক্ষণ সাহায্য করতে থাকে যতক্ষণ বান্দা তার ভাইয়ের সাহায্য করতে থাকে।
  //          (মুসলিম)
  //        </h3>

  //        <p>
  //          নিচে একটি ভিডিও দিচ্ছি হয়তো অনেকেই দেখতেও আতকে উঠবেন, সহ্য হবেনা একটা
  //          ছোট বাচ্চার কষ্টের অবস্থা দেখে.... একটু ভাবি, আমার মেয়েটার কিংবা আমার
  //          ছোট আদরের বোনটার যদি এমন অবস্থা হতো!!! আল্লাহ সবাইকে হেফাজত করুন আমীন।
  //          বাবাটির আর্থিক সামর্থ্য নেই, আসুন একটু এগিয়ে আসি সাহায্যের হাত
  //          বাড়িয়ে দেই, যে যেভাবে পারি...
  //        </p>

  //        <p>
  //          বর্তমানে "আই সি ইউ" তে আছে "ইসলামীয়া হাসপাতাল আগ্রাবাদে" আমার মসজিদের
  //          নিয়মিত মুসল্লী এক ভাইয়ের মেয়ে.. যারা ডাক্তার আছেন তারা যদি সম্ভব
  //          হয় কোনো ভাবে সাহায্য করতে পারেন এবং তাদের আর্থিক সহায়তা ও প্রয়োজন,
  //          আপনাদের পক্ষে সম্ভব হলে প্লিজ এসে দেখে যান এবং যে যেভাবে পারেন মানুষিক
  //          ভাবে কিংবা আর্থিকভাবে কিংবা চিকিৎসা ক্ষেত্রে সাহায্য করে প্লিজ come
  //          forward.....
  //        </p>

  //        <p>
  //          وَمَنْ كَانَ فِي حَاجَةِ أَخِيهِ، كَانَ اللَّهُ فِي حَاجَتِهِ، وَمَنْ
  //          فَرَّجَ عَنْ مُسْلِمٍ كُرْبَةً فَرَّجَ اللَّهُ عَنْهُ كُرْبَةً مِنْ
  //          كُرُبَاتِ يَوْمِ الْقِيَامَةِ রাসূলুল্লাহ সাল্লাল্লাহু আলাইহি
  //          ওয়াসাল্লাম বলেছেন, যে ব্যক্তি তার ভাইয়ের প্রয়োজন পূরণে থাকবে,
  //          আল্লাহ সুবহানাহু ওয়া তা'আলা তার প্রয়োজনে থাকবেন, কেউ যদি তার মুসলিম
  //          ভাইয়ের, দুনিয়ার কোন একটি কষ্ট দূর করে দেয় ,আল্লাহ সুবহানাহু ওয়া
  //          তা'আলা কেয়ামতের দিনের কষ্টগুলো থেকে তার একটি কষ্ট দূর করে দেবেন।
  //          (মুসলিম)
  //        </p>

  //        <img src={sjslatest} alt="sjs-latest" className="w-25 mb-5" />
  //        <br />
  //        <a
  //          href="https://www.facebook.com/photo?fbid=1708814409483700&set=a.316002708764884"
  //          target="__blank"
  //          className="border p-2 bg-success text-white rounded"
  //        >
  //          Check latest update
  //        </a>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">সহযোগিতার জন্যঃ </h4>
  //        <p>বিকাশ (পার্সোনাল): 01717190416 (বাচ্চার বাবা)</p>
  //        <p>বিকাশ (পার্সোনাল): 01849669334 (বাচ্চার বাবার বন্ধু)</p>
  //        <p>বিকাশ (পার্সোনাল): 01312873265 (বাচ্চার চাচা)</p>
  //        <p>নগদ (পার্সোনাল): 01300025360</p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/profile.php?id=100010654719908`,
  //    projectOrgName: "Wasiuz Zaman",
  //    projectOrg: { _ID: "sdqme0041", name: "Wasiuz Zaman" },
  //    projectCreated: "Aug 2022",
  //    projectTag: "শিশু, ICU",
  //    projectLink:
  //      "https://www.facebook.com/100010654719908/videos/1216406832485364?utm_source=sme_listing",
  //    get getURL() {
  //      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
  //    },
  //  },

  //  {
  //    _ID: "sdqme0040.1",
  //    projectImg: revertedcomrade,
  //    projectHeading: "ভয়াবহ রকমের অর্থকষ্টে ভুগছেন দুটি বাচ্চা নিয়ে",
  //    slug: "help-reverted",
  //    projectDetails: (
  //      <div>
  //        <h3 className="mb-4">
  //          জ্যোতিকা চক্রবর্তী মেয়েটি এখন আতিয়া ইবনাত সাদিয়া। চক্রবর্তী
  //          হিন্দুধর্মের সবচেয়ে উঁচু বংশ। সেই উঁচু বংশের মেয়েটি রিভার্টেড হয়ে
  //          ইসলাম গ্রহণ করেন এবং সজিব নামের একটি মুসলিম ছেলেকে বিয়ে করেন। এটা
  //          ২০১৫/১৬ সালের দিকের ঘটনা। এখন দুই সন্তানের জননী।
  //        </h3>

  //        <p>
  //          এই ইস্যুকে কেন্দ্র করে তখন একটা মিথ্যা মামলা হয়। সেই মামলায় এত বছর পর
  //          সজীব ভাইকে এই রমজানে (২০২২) গ্রেপ্তার করা হয়। আজ এই কয়টি মাস তিনি বিনা
  //          কারণে কারাগারে। আর দু'টি বাচ্চা নিয়ে আতিয়া আপু পড়েছেন জীবন টানাটানিতে।
  //          পাশে নেই দুজনের কারো পরিবার। ভয়াবহ রকমের অর্থকষ্টে ভুগছেন তিনি তার
  //          একটা ছেলে আর মেয়ে বাবু নিয়ে। সংসারের আয়রোজগারের একমাত্র মাধ্যম স্বামী
  //          জেলে যাওয়ায় ওনাদের শুরু হয়েছে অভাব। বাসা ভাড়াটাও দিতে পারছেন না।
  //          পাশাপাশি আছে খাবারের সংকট। না বাচ্চাদের মুখে কিছু তুলে দিতে পারছেন, না
  //          স্বামীর জন্য আইনী লড়াই করতে পারছেন, না কারো কাছে যেতে পারছেন সাহায্য
  //          চাইতে!
  //        </p>

  //        <p>
  //          ওনার ধর্মান্তরের এফিডেভিট কপিও আমাদের কাছে আছে। বড় ছেলেটির বয়স ৬ বছর,
  //          একটি নূরানী মাদ্রাসায় পড়ছে। মেয়েটি একদম ছোট। ওনার বাচ্চা দুটোর দিকে
  //          তাকালে আপনার কান্না আসবে, এত ফুটফুটে সুন্দর মায়াবি দুইটা শিশু মা-সহ কী
  //          কষ্টে আছে! কী বিপদে আছে!
  //        </p>

  //        <p>
  //          শুধু একটু ভাবুন, তিনি ধর্মান্তরিত মুসলিমাহ। একজন ধর্মান্তিরত মুসলিমকে
  //          যখন ত্যাজ্য করা হয়, উনি যে কী পরিমাণ বিপদের মুখোমুখি হতে পারেন, যিনি
  //          এসবের মধ্যদিয়ে গেছেন কেবল তিনিই বুঝতে পারবেন! যেহেতু পারিবারিকভাবে
  //          কারো কাছ থেকেই তিনি আর্থিক মানসিক কোনো সাপোর্টই পাচ্ছেন না, এবং
  //          স্বামীও কারাগারে, আয়োরোজগারের সমস্ত পথ বন্ধ।
  //        </p>

  //        <p>
  //          আমরা তাই চাইলেই উনার পাশে দাড়াতে পারি। যার যার সাধ্যমতো নগদ টাকা
  //          পাঠানো যা দিয়ে সজিবের জন্য আইনি লড়াই চালাতে পারেন। ইতিমধ্যে আমি
  //          ব্যাক্তিগতভাবে আইনজীবীর সাথে আলোচনা করে, প্রাথমিক কিছু টাকা দিয়ে
  //          মামলাটি হাইকোর্টে তুলতে ফাইল রেডি করিয়েছি। রবি সোমবার ইনশাআল্লাহ
  //          মামলাটি হাইকোর্টে উঠবে। মামলা পরিচালনার সব খরচ আমার একার পক্ষে বহন করা
  //          বেশ কষ্টসাধ্য। সাথে আছে দুটি বাচ্চা নিয়ে বেঁচে থাকার খরচ। মামলা
  //          পরিচালনা করতে বেশ কিছু টাকা দরকার এবং চাল ডাল তেল ইত্যাদি কিনে দেওয়া
  //          দরকার, আছে বাসা ভাড়া! অর্থাৎ যে যার সাধ্যমতো নগদ টাকা অথবা চাল ডাল
  //          কিনে দিতে পারেন। আমার ভাই বোনেরা চাইলে এই খরচ সবাই মিলে ভাগাভাগি করে
  //          নিতে পারি।
  //        </p>
  //        <h4>
  //          আপনি চাইলে এক বস্তা চালও পাঠাতে পারেন, অথবা টাকাও, অথবা চাইলে দুইটাই।{" "}
  //        </h4>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">
  //          পরিবারের সাথে যোগাযোগ এবং আর্থিক সাহায্য পাঠানোর পারসোনাল নাম্বার:{" "}
  //        </h4>
  //        <p>বিকাশ: 01888687878, 01688851590</p>
  //        <p>রকেট: 01688851590 8</p>
  //        <p>নগদ: 01888687878</p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/comrademahmud?utm_source=sme_listing`,
  //    projectOrgName: "কমরেড মাহমুদ",
  //    projectOrg: { _ID: "sdqme0040", name: "কমরেড মাহমুদ" },
  //    projectCreated: "Aug 2022",
  //    projectTag: "পরিবার, Reverted",
  //    projectLink:
  //      "https://www.facebook.com/photo?fbid=3263222570630178&set=pcb.3263222650630170?utm_source=sme_listing",
  //    get getURL() {
  //      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
  //    },
  //  },

  {
    _ID: "sdqme016.1",
    projectImg: kaffaraTanbir,
    projectHeading: "আহারের মাধ্যমে কাফফারা আদায়",
    slug: "kaffara-adai",
    projectDetails: (
      <div>
        {" "}
        গরিব অসহায় মানুষকে একবেলা ফ্রি খাবার বিতরণ করা হয়।
        <p>বিকাশ, নগদ: 01568085580, 01818835477 </p>
        <a
          href="https://www.facebook.com/tanbir.ahmad.336"
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          প্রজেক্ট পরিচালকের ফেসবুক আইডিতে যোগাযোগ করতে ক্লিক করুন{" "}
        </a>{" "}
      </div>
    ),
    //projectFbProfile: `https://fb.com/tanbir.ahmad.336`,
    projectOrgName: "কাফফারা আদায়",
    projectOrg: { _ID: "sdqme016", name: "কাফফারা আদায়" },
    projectCreated: "Januray 2022",
    projectTag: "কসম ভঙ্গের কাফফারা",
    projectLink: "https://www.facebook.com/groups/2232751196866758",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqme0037.1",
  //    projectImg: sylhetRebuilt2,
  //    projectHeading:
  //      "বিশ্বজিত, প্রবীর, প্রদীপ, রবেন্ড দাসের (৬টি পরিবার) জন্য সহায়তা",
  //    slug: "sylhet-rebuilt-2",
  //    projectDetails: (
  //      <div>
  //        <h3 className="mb-4">
  //          সিলেটে ২০২২ সালের অস্বাভাবিক বন্যার কারণে আপনারা সকলেই জানেন অসংখ্য
  //          পরিবার ক্ষতিগ্রস্থ হয়েছে। আমরা চাইলে সব পরিবারের তথ্য একসাথে একত্র
  //          করতে পারছি না। চেষ্টা শুরু করেছি ক্ষতিগ্রস্থ কিছু পরিবারের প্রাথমিক
  //          তথ্য সংগ্রহ করতে যাতে আমাদের সাইটের ভিজিটরদের জন্য সহজ হয় তাদের খুঁজে
  //          পেতে। এরই পরিপ্রেক্ষিতে আমরা আরো ৬টি পরিবারের সন্ধান পেয়েছি যাদের
  //          ঠিকানা গোলাপগঞ্জ থানাধীন, প্রাথমিক তথ্য নিচে উল্লেখ করা হল।
  //        </h3>

  //        <p>
  //          পরিবারের সম্মানিত প্রতিনিধিসমূহের নাম (সাথে যোগাযোগ নাম্বার যুক্ত করা
  //          আছে)
  //          <br />
  //          ১/ বিশ্বজিত দাস, মোবাইল 01724344958
  //          <br />
  //          ২/প্রবির দাশ। মোবাইল, 01783625321
  //          <br />
  //          ৩/ প্রদিপ দাস, মোবাইল , 01771862196.
  //          <br />
  //          ৪/ রবেন্ড দাস, মোবাইল, 01731390590
  //          <br />
  //          ৫/ অরুন দাস (নাম্বার সংগ্রহ করা সম্ভব হয় নি)
  //          <br />
  //          ৬/ প্রতাপ দাস (নাম্বার সংগ্রহ করা সম্ভব হয় নি )
  //        </p>

  //        <p>
  //          নোট: যারা সিলেটে বন্যা কবলিত অঞ্চলগুলোতে ত্রাণ বিতরণে সক্রিয়ভাবে কাজ
  //          করেছেন, প্রাথমিকভাবে আমরা তাদের কাছ থেকেই তথ্য সংগ্রহ করছি। আমাদের
  //          চেষ্টা থাকবে অন্তন্ত একটি পরিবার হলেও আমাদের প্ল্যাটফর্মের মাধ্যমে
  //          যাতে উপকৃত হোন সে-ই লক্ষ্যে কাজ করার।
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">সাহায্য পাঠানোর জন্যঃ</h4>
  //        {/*<p>বিকাশ, নগদ, রকেট: 01717566822</p>
  //        <b>টাকা প্রেরণ করলে দয়া করে কল করে জানাবেন৷</b>*/}

  //        <p className="mt-4">
  //          সাহায্য পাঠানোর আগে যোগাযোগ: 01778-183509 - নাম্বারটি উক্ত পরিবারের
  //          তথ্য সংগ্রহে যিনি নিযুক্ত ছিলেন, তার নাম্বার । যোগাযোগ করলে অবশ্যই
  //          উপরে উল্লেখিত গোলাপগঞ্জ থানাধীন পরিবার(গুলোর) সাথে যোগাযোগ করতে
  //          চাচ্ছেন তা জানানোর অনুরোধ করা হল।
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/profile.php?id=100044672869186`,
  //    projectOrgName: "আশিক এলাহী",
  //    projectOrg: { _ID: "sdqme0037", name: "আশিক এলাহী" },
  //    projectCreated: "June 2022",
  //    projectTag: "Sylhet, Resettlement",
  //    projectLink: "https://tinyurl.com/bishwajit-families",
  //  },

  {
    _ID: "sdqme026.1",
    projectImg: ramfit,
    projectHeading: "Research Academy for Medical Fiqh & Islamic Treatment",
    slug: "ramfit",
    projectDetails: (
      <div>
        <p>RAMFIT Account Number: 20503320100175412</p>
        <p>Account type: Current Account</p>
        <p>Bank: Islami Bank Bangladesh Limited</p>
      </div>
    ),
    projectContact: `+880 1713-483396`,
    projectFbProfile: `https://fb.com/drmsisharif`,
    projectOrgName: "RAMFIT",
    projectOrg: { _ID: "sdqme026", name: "RAMFIT" },
    projectCreated: "March 2022",
    projectTag: "Medical fiqh, Academy",
    projectLink: "https://www.ramfit.org/donate-now?utm_source=sme_listing",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  {
    _ID: "sdqme010.1",
    projectImg: TanbirProj1lakh,
    projectHeading: "আনুমানিক ১ লাখ+ টাকা প্রয়োজন",
    slug: "tanbir-madrasa-rangamati",
    projectDetails: `কাজটি শেষ করতে  মিস্ত্রি বিল খুবই জরুরি। 
ডেইলি মিস্ত্রি বিল দুই হাজার করে লাগবে আনুমানিক। 
রাঙামাটির দুর্গম পাহাড়ি এলাকায় নির্মাণাধীন মহিলা হিফয মাদ্রাসা ও এতিমখানার চালের কাজ শুরু হবে শীঘ্রই ইনশাআল্লাহ। 
মিস্ত্রি বিল এখনো আনুমানিক ১ লাখ টাকার উপরে লাগবে।
মিস্ত্রি বিল সহ অনেক টাকার প্রয়োজন। 
অত্র অঞ্চলে গরিব, অসহায়, এতিম,সহ সর্বস্তরের ছাত্রীদের জন্য উম্মুক্ত হবে এই মাদ্রাসা ইনশাআল্লাহ। 
সকলেই এগিয়ে আসি। 

রাঙামাটি।
`,
    projectFbProfile: `https://fb.com/tanbir.ahmad.336`,
    projectContact: `বিকাশ / নগদ / রকেটঃ 
01608184511 (Personal)
`,
    projectOrgName: "Tanbir Ahmad",
    projectOrg: { _ID: "sdqme010", name: "Tanbir Ahmad" },
    projectCreated: "December 2021",
    projectTag: "মাদ্রাসা, Rangamati",
    projectLink:
      "https://fb.com/permalink.php?story_fbid=433912584853838&id=100047052998545",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  {
    _ID: "sdqme010.2",
    projectImg: TanbirProjVola,
    projectHeading: "আপনাদের উসিলায় নির্মিত হবে মসজিদ",
    slug: "tanbir-mosque-vola",

    projectDetails: `আপনাদের অসিলায় নির্মিত হবে মাদ্রাসা মসজিদ টি ইনশাআল্লাহ। 
আরও অনেক টাকার ইট,বালি,সিমেন্ট লাগবে।
ভোলা মাদ্রাসা মসজিদের জন্য ২ হাজার ইট,১০০ ফুট বালি ও ১০ বস্তা সিমেন্ট আজ আনা হয়েছে আলহামদুলিল্লাহ। 
👉 টোটাল ইট লাগবে ১০ হাজার।
প্রতি হাজার ইট  ৮ ০০০ টাকা।
👉 বালি আনুমানিক ১০০০ফুট 
প্রতি ফুট ২৩ টাকা।
👉 ১০০ বস্তা আনুমানিক। 
প্র‍তি বস্তা ৫০০ টাকা।
সকলেই সাধ্যমতো অর্থনৈতিক সহযোগিতা করি। 

ভোলা।
`,
    projectFbProfile: `https://fb.com/tanbir.ahmad.336`,
    projectContact: `বিকাশ / নগদ / রকেটঃ 
01715418891 (Personal)
`,
    projectOrgName: "Tanbir Ahmad",
    projectOrg: { _ID: "sdqme010", name: "Tanbir Ahmad" },
    projectCreated: "December 2021",
    projectTag: "ভোলা, Materials",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=434563774788719&id=100047052998545",
    get getURL() {
      return `https://sadaqahmadeeasy.com/details/${this.slug}`;
    },
  },

  {
    _ID: "sdqme001.2",
    projectImg: NolkupSdq3,
    projectHeading: "নলকূপ প্রজেক্ট",
    slug: "sadaqah-nolkup",
    projectDetails: `নলকূপ প্রজেক্ট ২ এবং ৩ আপডেটঃ
আলহামদুলিল্লাহ আমাদের দ্বিতীয় ও তৃতীয় নলকূপ প্রজেক্ট আল্লাহর অশেষ রহমতে সফলভাবে সমাপ্ত হয়েছে। মহেশখালীর পশ্চিম ফকিরাঘোনা গ্রামে আমাদের প্রথম নলকূপ প্রজেক্টের পরপরই আমরা নোয়াখালীর সূবর্ণচর আর ভোলার তজুমদ্দিনে আরও দু’টি নলকূপ প্রজেক্টের কাজ শুরু করি। আপনাদের ব্যাপক সাড়া এবং সাদাকায় আলহামদুলিল্লাহ আমরা দু’টি প্রজেক্টই শেষ করেছি। 
নোয়াখালীর সূবর্ণচরে আমরা ৪৮৫ ফুট সাবমার্সিবল নলকূপ বসিয়েছি। এই নলকূপ থেকে উপকৃত হবে একটি মাদ্রাসার ২৫০ জন শিক্ষার্থী আর আশেপাশের বাজার ও এলাকাবাসী। এখানে আমাদের মোট খরচ হয়েছে ৬৪ হাজার টাকা। এই প্রজেক্টে আমরা কেবল নলকূপ বসানোর খরচ বহন করেছি, বাকি ফ্লোর পাকা করা আর মিস্ত্রি খরচ মাদ্রাসা থেকেই বহন করা হয়েছে। ফলে আমরা প্রাথমিকভাবে ৭০ হাজার টাকা বাজেট করলেও পরবর্তীতে কিছুটা সাশ্রয় হয়েছে। 
ভোলার তজুমদ্দিনে আমরা ১০০০ ফুট গভীর নলকূপ স্থাপন করেছি যেখান থেকে সুপেয় পানি পান করতে পারবে সংশ্লিষ্ট মাদ্রাসার ২০০ শিক্ষার্থী আর এলাকাবাসী। এখানে আমরা পুরো খরচই বহন করেছি এবং সর্বমোট ব্যয় করেছি ৫৭ হাজার টাকা। 
আল্লাহ চাইলে আমরা আরও বেশ কিছু নলকূপ প্রজেক্টের পরিকল্পনা করছি। ইনশাল্লাহ, অতিশীঘ্রই তা ফেসবুক পোস্টের মাধ্যমে জানিয়ে দেয়া হবে। আপনারা যারাই আমাদের সাদাকা প্রদান করে আর দু’য়া করে সহায়তা করেছেন, প্রত্যেককে আল্লাহ হাশরের ময়দানে উত্তম প্রতিদান দিন।
`,
    projectContact: `আপনার সাদাকা পাঠাতে পারেন নিচের পদ্ধতিতেঃ
বিকাশ মার্চেন্টঃ
01903026868
(এই নাম্বারে আপনি 'পেমেন্ট' অপশনে গিয়ে টাকা পাঠাবেন) ||
বিকাশ পার্সোনালঃ
01713639791
01720475156
(এই নাম্বারে আপনি 'সেন্ড মানি' অপশনে গিয়ে টাকা পাঠাবেন) ||
রকেট পার্সোনালঃ
019196397911 ||
নগদ পার্সোনালঃ
01919639791
01822268474 ||
ব্যাংকঃ
Islami Bank Bangladesh.
A/C no: 20501930203364301
A/C Name: MISBAH UDDIN AHMAD
CDA Avenue Branch ||
কেউ যদি নগদ অর্থে সাদাকা দিতে চান, কিংবা অন্য কোন ধরণের তথ্য অনুসন্ধানের জন্য যোগাযোগ করতে চান, তবে নিচের নাম্বারগুলোতে যোগাযোগ করুন।
01713639791
01720475156 ||
জাযাকুমুল্লাহু খাইরান।
`,
    projectOrgName: "সাদাকা",
    projectOrg: { _ID: "sdqme001", name: "সাদাকা" },
    projectCreated: "January 2022",
    projectTag: "পানি, Water",
    projectLink:
      "https://www.facebook.com/sadaqahthecharity/posts/1917046178467424",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },
];

export function getProjects() {
  return projects;
}

//get project
export function getProject(id) {
  return projects.find((p) => p._ID === id);
}

//get images
export function getImg() {
  let img = projects.map((i) => i.projectImg);
  return img;
}

//get heading
export function getHeading() {
  let heading = projects.map((h) => h.projectHeading);
  return heading;
}

// get organizations
export function getOrg() {
  let org = projects.map((p) => p.projectOrgName);
  return org;
}

// get date
export function getDate() {
  let date = projects.map((d) => d.projectCreated);
  return date;
}

// get tag
export function getTag() {
  let tag = projects.map((t) => t.projectTag);
  return tag;
}

// get link
export function getLink() {
  let link = projects.map((l) => l.projectLink);
  return link;
}

export function saveProject(project) {
  let projectInDb = projects.find((p) => p._ID === project._ID) || {};
  projectInDb.projectHeading = project.projectHeading;
  projectInDb.projectOrgName = organizationsAPI.organizations.find(
    (p) => p._ID === project.categoryId
  );
  projectInDb.projectCreated = project.projectCreated;
  projectInDb.projectTag = project.projectTag;

  if (!projectInDb._ID) {
    projectInDb._ID = Date.now();
    projects.push(projectInDb);
  }
  return projectInDb;
}
