import medlist from "../img/medlist.png";

function MedList() {
  return (
    <div className="position-relative pt-5">
      <div className="text-center pt-5 mt-5">
        <a
          href="https://drive.google.com/file/d/1qYHjCp3NU-OsyjkPrBusK-u29qVxpmgq/view?usp=sharing"
          className="btn btn-lg btn-danger"
          target="_blank"
          rel="noreferrer"
        >
          মেডিসিন লিস্ট ডাউনলোড করতে ক্লিক করুন
        </a>
      </div>
      <img src={medlist} alt="medlist" className="w-100" />
    </div>
  );
}

export default MedList;
