import alasr1 from "../img/alasr1.jpeg";
//import alasr2 from "../img/alasr2.jpeg";
//import labbaik1 from "../img/labbaik1.jpeg";
//import labbaik2 from "../img/labbaik2.jpeg";
//import labbaik3 from "../img/labbaik3.jpeg";
//import sadaqahkurigram from "../img/sadaqahkurigram.jpeg";

import sylhetfloodsiyanah from "../img/sylhetfloodsiyanah.jpeg";
//import alburhanpak from "../img/alburhanpak.jpeg";
//last serial: 4

const FloodProjects = [
  //  {
  //    _ID: "sdqmen001.4",
  //    projectImg: alburhanpak,
  //    projectHeading: "Pakistan - Al Burhan Floods Emergency Appeal",
  //    metaTitle: "Al Burhan Floods Emergency Appeal",
  //    slug: "alburhan-pakistan-flood",
  //    projectDetails: (
  //      <div>
  //        <h3>মারাত্মক বন্যার কবলে পাকিস্তান, জরুরি অবস্থা ঘোষণা</h3>
  //        <p>
  //          পাকিস্তানে বন্যায় এ পর্যন্ত ৩৪৩ শিশুসহ ৯৩৭ জনের মৃত্যু এবং অন্তত তিন
  //          কোটি মানুষ আশ্রয়হারা হয়েছে। নজিরবিহীন বৃষ্টিপাতে সৃষ্ট প্রাণঘাতী,
  //          ধ্বংসাত্মক বন্যা পরিস্থিতিতে ‘জাতীয় জরুরি অবস্থা’ ঘোষণা করছে পাকিস্তান
  //          সরকার। বৃহস্পতিবার বর্ষাকালীন অবিশ্রান্ত বর্ষণকে ‘মহাকাব্যিক পর্যায়ের
  //          জলবায়ুজনিত মানবিক সংকট’ অভিহিত করে আনুষ্ঠানিকভাবে এ ঘোষণা দেয় তারা।
  //        </p>
  //        <p>
  //          বেলুচিস্তানে প্রদেশে ২৩৪ জন, খাইবার পাখতুনখোয়ায় ১৮৫, পাঞ্জাবে ১৬৫ জন
  //          মারা গেছে। এছাড়া আজাদ জম্মু ও কাশ্মীরে ৩৭ এবং গিলগিট-বালতিস্তান অঞ্চলে
  //          ৯ জনের মৃত্যু হয়েছে। একই সময় ইসলামাবাদেও একজনের মৃত্যু হয়।
  //        </p>
  //        <p className="fw-bol">
  //          আমাদের তালিকাভুক্ত প্রজেক্টটি (যেটি আল-বুরহান সংস্থার অধীনে পরিচালিত
  //          হচ্ছে), কাজ করছে বালুচিস্তান, পাঞ্জাব এবং সিন্ধু প্রদেশের উপকূলবর্তী
  //          এলাকায়।
  //        </p>
  //        <a
  //          href="https://www.facebook.com/muftiadnankakakhail/videos/2006642672859272/"
  //          target="__blank"
  //          className="text-primary "
  //        >
  //          আপডেট দেখুন এখানে
  //        </a>

  //        <p className="mt-4">
  //          রেফারারের পক্ষ থেকে ত্রাণ কর্মকান্ড নিয়ে করা একটি টুইটঃ{" "}
  //        </p>
  //        <p>
  //          ❝যখন আল-বুরহান ফ্রি মেডিকেল ক্যাম্পে বিনামূল্যে ওষুধের আর ডাক্তারের
  //          প্রয়োজন বলা দেয়, তখন প্রথম লাবিক খায়রপুরের একজন হিন্দু ডাক্তার ডাঃ
  //          কৈলাশ কুমার সাহেব সাড়া দিয়েছিলেন এবং রোগীদের সেবা প্রদান করেছিলেন।
  //          উল্লেখ্য যে, আল-বুরহান সংস্থা ত্রাণ বিতরণে জরিপের কাজে নিয়োজিত
  //          টীমগুলোকে দুর্দশাগ্রস্তদের মধ্যে অমুসলিম সংখ্যালঘুদের প্রতি বিশেষ যত্ন
  //          নেওয়ার আহ্বান জানিয়েছে।❞
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <div className="mt-5 mb-2">
  //          <h3 className="mt-4 mb-4">
  //            বাংলাদেশ থেকে ব্যাংক ট্রান্সফার করতে চাইলে
  //          </h3>

  //          <h4> For Zakat</h4>
  //          <p>
  //            Bank: UBL Account
  //            <br />
  //            Title: Zahid Mahmood
  //            <br />
  //            Account Number: 1092248700751
  //            <br />
  //            IBAN: PK37UNIL0109000248700751
  //            <br />
  //            Branch: F-10 Markaz
  //            <br />
  //            Swift Code: UNILPKKA
  //          </p>
  //          <hr />
  //          <h4> For Sadaqah / Atiyat</h4>
  //          <p>
  //            Bank: UBL Account
  //            <br />
  //            Title: Muhammad Yazdan Kundi
  //            <br />
  //            Account Number: 1048271561598
  //            <br />
  //            IBAN: PK81UNIL0109000271561598
  //            <br />
  //            Branch: G-11 Markaz
  //            <br />
  //            Swift Code: UNILPKKA
  //          </p>
  //          <a
  //            href="https://albn.org/paypal"
  //            className="rounded border btn btn-sm mt-4 text-white bg-danger"
  //            target="__blank"
  //          >
  //            Click to donate via Paypal
  //          </a>
  //          {/*<p>For Pakistan Bank Details (WhatsApp): 0300-0300-111</p>*/}
  //        </div>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/MuftiSyedAdnanKakakhail`,
  //    projectOrgName: "Al-Burhan organization",
  //    projectOrg: { _ID: "sdqmen004", name: "Al-Burhan organization" },
  //    projectCreated: "Aug 2022",
  //    projectTag: "Flood, Pakistan",
  //    projectLink: "https://www.facebook.com/alburhanorg",
  //    get getURL() {
  //      return `https://sadaqahmadeeasy.com/flood/${this.slug}`;
  //    },
  //  },

  {
    _ID: "sdqmen001.1",
    projectImg: alasr1,
    projectHeading: "নেত্রকোণা - পূনর্বাসন প্রজেক্টে সাহায্য করুন",
    metaTitle: "Al-Asr Foundation",
    slug: "netrokona-resettle",
    projectDetails: (
      <div>
        <h3>
          বন্যার পানি কমে গেছে শুনেই আমরা বন্যার্তদের থেকে দৃষ্টি ফিরিয়ে নিয়েছি।
          পানি কমেছে বটে কিন্তু রেখে গেছে তার ভয়ঙ্কর ছাপ। ভাসিয়ে নিয়ে গেছে বহু
          সহায়-সম্বলহীন মানুষের মাথাগোঁজার ঠাঁইটুকু।
        </h3>
        <p>
          ত্রাণ বিতরণকালীনই বন্যায় ঘর-বাড়ি ভাঙা বেশ কিছু পরিবারের পক্ষ থেকে
          আমাদের কাছে আবেদন আসে। যাচাই-বাছাই শেষে আমরা ১০টি পরিবারের আবেদন গ্রহণ
          করি। ঘরগুলো হবে ১৫ × ১০ এবং ২০ × ১০ ফিট।
        </p>
        <p>
          ১৫ × ১০ ফিট প্রতিটি ঘর নির্মাণে খরচ হবে আনুমানিক ৩৫,০০০ টাকা। আর ২০ ×
          ১০ প্রতিটি ঘরের পিছনে খরচ হবে আনুমানিক ৫০,০০০ টাকা।
        </p>

        <p>আপনার সাদাকায় হোক বন্যায় ক্ষতিগ্রস্ত পরিবারের মাথাগোঁজার ঠাঁই।</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <div className="mb-2">
          <h4>বিকাশ, রকেট, নগদ: 01796-459889 </h4>
        </div>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/IbnJakir`,
    projectOrgName: "Al-Asr Foundation",
    projectOrg: { _ID: "sdqmen001", name: "Al-Asr Foundation" },
    projectCreated: "Aug 2022",
    projectTag: "বন্যা, নেত্রকোনা",
    projectLink:
      "https://www.facebook.com/alasr.online/photos/a.1077235379116340/2147700352069832",
    get getURL() {
      return `https://sadaqahmadeeasy.com/resettle/${this.slug}`;
    },
  },

  {
    _ID: "sdqme034.1",
    projectImg: sylhetfloodsiyanah,
    projectHeading: "ভিটেমাটি বিহীনদের ঘরে ফেরাতে পুনর্বাসন প্রকল্প",
    slug: "sylhet-resettle-sianah",
    projectDetails: (
      <div>
        <h3>
          সিলেটে স্মরণকালের ভয়াবহ বন্যায় বিপর্যস্ত ভিটেমাটি বিহীন মানুষকে ঘরে
          ফেরাতে সিয়ানাহ ট্রাস্ট - Sianah Trust এর পুনর্বাসন প্রকল্প
        </h3>
        <p className="fw-bold mt-5">আমাদের কর্মসূচীঃ </p>
        <p>- সম্পূর্ণ ঘর স্রোতে ভাসিয়ে নিয়েছে, এমন ঘর পুনর্নিমাণ </p>
        <p>- আংশিক ভেঙ্গে যাওয়া ঘর সংস্কারকরণ</p>
        <p>- নির্মাণ সামগ্রী ক্রয় করে হস্তান্তর </p>
        <p>- নগদ অর্থ প্রদান</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <div className="mb-5">
          <h4 className="mb-3">BKASH PERSONAL (Send Money): </h4>
          {/*<p>01717 953058 - মুফতি জিয়াউর রহমান - পরিচালক </p>*/}
          <p>01710 446038 - MD ABDUL HAMID SAKIB</p>
          <p>01765 204547 - ASAD UDDIN </p>
          <p>01741 585040 - ABDULLAH AL MONSUR </p>
        </div>

        <div className="mb-5">
          <h4 className="mb-3">ROCKET - PERSONAL: </h4>
          <p>01724 344251-8</p>
        </div>

        <div className="mb-5">
          <h4 className="mb-3">NAGAD - PERSONAL: </h4>
          <p>01724 344251 - MD SAIFUR RAHMAN</p>
        </div>

        <hr />
        <h4 className="mb-4">BANK-1</h4>
        <p>Bank: Islami Bank Bangladesh Limited</p>
        <p>Account Name : Ziaur Rahman </p>
        <p>MSA Account No : 2050 1660 2034 76507 </p>
        <p>Branch: Ambarkhana, Sylhet</p>
        <br />
        <br />
        <h4 className="mb-4">BANK-2</h4>
        <p>Bank: Islami Bank Bangladesh Limited</p>
        <p>Account Name : Asad Uddin </p>
        <p>Account No : 2050 1660 2032 11311 </p>
        <p>Branch: Ambarkhana, Sylhet</p>

        <hr />
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/Zia.Eshona`,
    projectOrgName: "সিয়ানাহ ট্রাস্ট",
    projectOrg: { _ID: "sdqme034", name: "সিয়ানাহ ট্রাস্ট" },
    projectCreated: "June 2022",
    projectTag: "বন্যা, Resettlement",
    projectLink:
      "https://www.facebook.com/photo?fbid=2208291415993992&set=a.105824529574035",
    get getURL() {
      return `https://sadaqahmadeeasy.com/resettle/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqmen003.1",
  //    projectImg: sadaqahkurigram,
  //    projectHeading: "ভয়াবহ বন্যার্ত অঞ্চল – কুড়িগ্রামে সহায়তা",
  //    metaTitle: "Sadaqah The Charity",
  //    slug: "kurigram-aid-sadaqah",
  //    projectDetails: (
  //      <div>
  //        <h4>
  //          আসসালামু আলাইকুম। এই মুহুর্তে সারা দেশ সিলেটবাসীর দুর্ভোগে সর্বোতভাবে
  //          এগিয়ে আসলেও, কিছুটা পাদপ্রদীপের আড়ালেই থেকে যাচ্ছে আরও একটি ভয়াবহ
  //          বন্যার্ত অঞ্চল – কুড়িগ্রাম। কুড়িগ্রামে গত কিছুদিনে প্রায় ৫০টি ইউনিয়নের
  //          ২৮৫টি গ্রাম ভেসে যায়, শুধু সরকারি হিসেবেই পানিবন্দী হয় প্রায় সোয়া লাখ
  //          মানুষ। পানিতে ডুবে গেছে প্রায় ১৪ হেক্টর জমি আর ১২০০ মাছের খামার।{" "}
  //        </h4>
  //        <p>
  //          সরকারের পক্ষ থেকে প্রায় সাড়ে পাঁচশ মেট্রিক টন চাল আর প্রায় ৪০ লাখ টাকা
  //          ব্যয় করার কার্যক্রম চললেও, বানভাসি মানুষের জন্য তা অপ্রতুল। স্থানীয়
  //          সাংবাদিকদের ভাষ্যমতে যথেষ্ট ত্রাণ পাচ্ছেনা ভুক্তভোগীরা।
  //        </p>

  //        <p>
  //          গত শুক্রবার বন্যার পানি কমে এসেছে, ব্রহ্মপুত্র আর ধরলার পানি এখন ধীরে
  //          ধীরে বিপদসীমার নিচে বইতে শুরু করেছে। কিন্তু চরাঞ্চলসহ নীচু এলাকার
  //          ঘর-বাড়িতে এখনো জমে আছে বন্যার পানি। এ অবস্থায় রান্না করা খাবার,
  //          বিশুদ্ধ পানি, গো-খাদ্যসহ নানা সংকটের পাশাপাশি এসব এলাকায় দেখা দিয়েছে
  //          পানিবাহিত রোগের প্রাদুর্ভাব। অধিকাংশ শিশু ডায়রিয়ায় আক্রান্ত। অনেকের
  //          হাতে-পায়ে দেখা দিয়েছে ঘা।
  //        </p>
  //        <p>
  //          মিডিয়া কভারেজের কারণে সিলেটে যে পরিমাণ ত্রাণ পৌছাচ্ছে, সে পরিমাণ ত্রাণ
  //          পাচ্ছে না কুড়িগ্রামবাসী। সাদাকা’র পক্ষ থেকে আমরা স্থানীয় কিছু
  //          সেচ্ছাসেবী সংগঠনের সাথে সমন্বয় করার সিদ্ধান্ত নিয়েছি। সিলেটের প্রজেক্ট
  //          শেষ করেই ইনশাআল্লাহ আমাদের টিম কুড়িগ্রাম রওনা দেবে।
  //        </p>
  //        <p>
  //          সিলেটবাসীর পাশাপাশি কুড়িগ্রামবাসীর পাশে দাঁড়ানোও আমাদের নৈতিক দায়িত্ব।
  //          আপনারাও আপনাদের সাদাকা নিয়ে দাঁড়াতে পারেন তাদের পাশে।
  //        </p>
  //        <p>প্রতিটি পরিবারের জন্য সাদাকার বন্যা প্রজেক্টে এবার যা যা থাকছেঃ</p>
  //        <ul className="list-group">
  //          <li className="list-group-item">চাল ৫ কেজি </li>
  //          <li className="list-group-item">আলু ৩ কেজি </li>
  //          <li className="list-group-item">ডাল ১ কেজি</li>
  //          <li className="list-group-item">তেল ১ লিটার </li>
  //          <li className="list-group-item">চিড়া ১ কেজি</li>
  //          <li className="list-group-item">লবণ ১/২ কেজি</li>
  //          <li className="list-group-item">মসলা ২০০ গ্রাম</li>
  //          <li className="list-group-item">সুজি ১ কেজি ( শিশু খাদ্য হিসাবে)</li>
  //          <li className="list-group-item">প্রয়োজনীয় ওষুধ</li>
  //          <li className="list-group-item">পানি বিশুদ্ধকরণ ওষুধ</li>
  //        </ul>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <div>
  //          <h4 className="mb-4">আপনার সাদাকা পাঠাতে পারেন নিচের পদ্ধতিতে</h4>
  //          <p>
  //            বিকাশ মার্চেন্টঃ 01903026868 (এই নাম্বারে আপনি 'পেমেন্ট' অপশনে গিয়ে
  //            টাকা পাঠাবেন)
  //          </p>
  //          <p>
  //            বিকাশ পার্সোনালঃ 01713639791, 01720475156 (এই নাম্বারে আপনি 'সেন্ড
  //            মানি' অপশনে গিয়ে টাকা পাঠাবেন){" "}
  //          </p>
  //          <p>রকেট পার্সোনালঃ 019196397911 </p>
  //          <p>নগদ পার্সোনালঃ 01919639791, 01822268474 </p>
  //          <p>
  //            ব্যাংকঃ Islami Bank Bangladesh.
  //            <br />
  //            A/C no: 20501930203364301
  //            <br />
  //            A/C Name: MISBAH UDDIN AHMAD
  //            <br />
  //            CDA Avenue Branch{" "}
  //          </p>
  //          <p>
  //            কেউ যদি নগদ অর্থে সাদাকা দিতে চান, কিংবা অন্য কোন ধরণের তথ্য
  //            অনুসন্ধানের জন্য যোগাযোগ করতে চান, তবে নিচের নাম্বারগুলোতে যোগাযোগ
  //            করুন। 01713639791, 01720475156{" "}
  //          </p>
  //          <p>জাযাকুমুল্লাহু খাইরান।</p>
  //        </div>
  //        <hr />
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/ahmedsagor.ctg/posts/pfbid023uUnzC7iZWFFjdvQUaA2oiHJ6sDqMihJoaMd4LMkizLYEwJ8wZ8nsvTEKcy84HG9l`,
  //    projectOrgName: "সাদাকা",
  //    projectOrg: { _ID: "sdqmen003", name: "সাদাকা" },
  //    projectCreated: "June 2022",
  //    projectTag: "বন্যা, কুড়িগ্রাম",
  //    projectLink:
  //      "https://www.facebook.com/sadaqahthecharity/photos/a.1408567245981989/2044581892380518",
  //  },

  //  {
  //    _ID: "sdqmen002.1",
  //    projectImg: labbaik1,
  //    projectHeading: "কুড়িগ্রামের বানভাসি মানুষের সেবায় ১৮ জুন থেকে",
  //    metaTitle: "Labbaik Kafela Kurigram",
  //    slug: "kurigram-flood-labbaik",
  //    projectDetails: (
  //      <div>
  //        <h4>১৯শে জুনের আপডেটঃ </h4>
  //        <p>
  //          আজ সারাদিন বিভিন্নস্থানে ডুবন্ত-অর্ধডুবো পরিবারগুলোকে খুঁজে বের করে এই
  //          সামগ্রীগুলো তাদের ঘর পর্যন্ত পৌঁছে দেওয়া হয়। আমাদের যা সামর্থ্য ছিল
  //          এবং যতটুকু হিম্মত ছিল তাই নিয়ে ময়দানে ঝাঁপিয়েপড়ি মানুষের সমস্যা
  //          সমাধানের চেষ্টায়। আল্লাহ চাহেন তো এর বিনিময় আমরা যত প্রযুক্ত
  //          কেয়ামতের দিন পাব ইনশাআল্লাহ। যিনি আমাদের এই কাজে সহযোগিতা করেছেন,
  //          আল্লাহ উনাকে উত্তম বিনিময় দান করুন।
  //        </p>
  //        <img src={labbaik2} alt="sylhetghonaighat" className="w-25 mt-3 mb-3" />

  //        <h4 className="mt-5">১৮ জুনের আপডেটঃ </h4>
  //        <p>
  //          এভাবেই গ্রামের পর গ্রাম উজাড় করে নিয়ে যাচ্ছে। আপনি সামনে যে চিত্রটি
  //          দেখছেন, এখানে কয়েকটি গ্রাম ছিল। অল্প সময়ের ব্যবধানে এখন নিশ্চিহ্ন,
  //          বুঝাই যায় না এখানে যে গ্রাম ছিল! সকাল থেকে কয়েকটি পরিবারকে উদ্ধার
  //          করে নিজ আশ্রয়ে রেখেছি, জানি না আল্লাহ এই পরিবারগুলোর জন্য কি ব্যবস্থা
  //          করবেন, দোয়া চাই।
  //        </p>
  //        <img src={labbaik3} alt="sylhetghonaighat" className="w-25 mt-3 mb-3" />
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <div className="mb-5">
  //          <h4 className="mb-3">বিকাশ: 01770-730209 </h4>
  //        </div>

  //        {/*<div className="mb-5">
  //          <h4 className="mb-3">রকেট (personal): 01870 20 56 92</h4>
  //        </div>

  //        <div className="mb-5">
  //          <h4 className="mb-3">নগদ (personal): 01880 554 222 </h4>
  //        </div>*/}

  //        <hr />
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/Ferdaus.kurigrami/`,
  //    projectOrgName: "লাব্বাইক কাফেলা কুড়িগ্রাম",
  //    projectOrg: { _ID: "sdqmen002", name: "লাব্বাইক কাফেলা কুড়িগ্রাম" },
  //    projectCreated: "June 2022",
  //    projectTag: "বন্যা, কুড়িগ্রাম",
  //    projectLink: "https://www.facebook.com/Labbaikkafela/",
  //  },
];

export function getFloodProjects() {
  return FloodProjects;
}
