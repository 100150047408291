import tarbiyahacad from "../img/tarbiyahacad.jpeg";
import JamiyaHafizulUlum from "../img/JamiyaHafizulUlum.jpeg";
import mahadulIman from "../img/mahadulIman.jpeg";
import markajLugatularabia from "../img/markajLugatularabia.jpeg";
import maktabPonchogor from "../img/maktabPonchogor.jpeg";
//import bahrululum from "../img/bahrululum.jpeg";
import jamiahasnain from "../img/jamiahasnain.jpeg";
import ummulmumenincover from "../img/ummulmumenincover.jpeg";
import ummulmumeninliflet from "../img/ummulmumeninliflet.jpeg";

//last serial:9

const MadrasaList = [
  {
    _ID: "sdqmet001.1",
    projectImg: tarbiyahacad,
    projectHeading: "তারবিয়াহ একাডেমি",
    slug: "tarbiyyah-academy",
    projectDetails: (
      <div>
        আসসালামু আলাইকুম ওয়া রহমাতুল্লাহি ওয়া বারকাতুহু
        <br />
        <br />
        <p>
          আমাদের তারবিয়াহ একাডেমি মূলত 'মারকাযুল উলূম আশ-শারইয়্যাহ ওয়া
          তারবিয়াতিল মুসলিমীন' এর শাখা কার্যক্রম। মারকাযের যেসব ছাত্ররা আবাসিক
          থেকে হিফয ইত্যাদি পড়াশোনা করে তাদের অধিকাংশই গরীব। যাদের খাবার, আবাসন,
          চিকিৎসা, কখনো পোশাক এমনকি শিক্ষা সফরের খরচও মারকায বহন করে। রমাদানের
          শেষ দশকে এমনিতেই দান করার গুরুত্বপূর্ণ সময়। মারকাযও সারা বছরের বার্ষিক
          খরচ রমাদানে সংগ্রহ করে থাকে। মারকাযের বহুমুখী খিদমতে আপনি নিজের
          যাকাত/সদকা/অনুদান দিয়ে অথবা পরিচিত কাউকে সাজেস্ট করেও সদকায়ে জারিয়াহর
          অংশী হতে পারেন।
        </p>
        <p>بارك الله فيكم! جزاكم الله احسن الجزاء!</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">মারকাযে অনুদান পাঠানোর মাধ্যম:</h4>
        <p>A/C Name: Ignite </p>
        <p>Bank A/C Number: 1532204553244001 </p>
        <p className="mb-4">
          {" "}
          Bank Name: BRAC Bank, Bijoy Nagar Branch, Dhaka{" "}
        </p>

        <hr />
        <p> bKash Number: 01965-159658 (Personal ) | সাধারণ অনুদানের জন্যে। </p>
        <p> bKash Number: 01747-031015 (Personal ) | শুধু যাকাত এর জন্যে। </p>
        <p> Nagad Number: 01810-113699 </p>
        <p> Rocket Number: 01965-1596583 </p>
        <p>(নগদ বা রকেটের মাধ্যমে যাকাত পাঠিয়ে থাকলে অবগত করতে হবে)</p>
        <span className="fw-normal">
          এছাড়া মারকাযের ব্যাংক হিসাবও আছে। মারকায সম্পর্কে আরো জানতে চাইলে
          ওয়েবসাইট ভিজিট করুন!
        </span>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/academytarbiyah/`,
    projectOrgName: "Tarbiyah academy",
    projectOrg: { _ID: "sdqmet001", name: "Tarbiyah academy" },
    projectCreated: "April 2022",
    projectTag: "Zakat, Sadaqah",
    projectLink: "https://www.markazululum.com/",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqme023.1",
    projectImg: markajLugatularabia,
    slug: "markajul-lugatil-arabiyyah",
    projectHeading: "মারকাজুল লুগাতিল আরাবিয়া",
    projectDetails: (
      <div>
        <p>লিখেছেন এস এম নাহিদ হাসানঃ </p>
        আজকে এক বরকতময় কাজে অংশ নিতে গিয়েছিলাম প্রিয় মারকাজুল লুগাতিল আরাবিয়া
        বাংলাদেশের নিজস্ব ভবন নির্মাণের স্থানে। এখানেই হবে স্বপ্নের আরবী ভাষার
        ঠিকানা। শাইখ Mohiuddin Faroqi হাফিজাহুল্লার তত্ত্বাবধানে পরিচালিত এই
        প্রতিষ্ঠান বাংলাদেশে আরবি ভাষা নিয়ে উচ্চতর গবেষণার একটি অন্যতম
        তীর্থস্থান বলা যায়। আমি নিজেকে এই প্রতিষ্ঠানের একজন খাদেম বলে মনে করি
        আলহামদুলিল্লাহ। এর নির্মিতব্য ভবনের ডিজাইনও আমিই করবো ইনশাআল্লাহ।
        ভাইয়েরা এর নির্মাণ কাজে আমরা শরীক হব ইনশাআল্লাহ। আপনার সাদাকাহ জারিয়া
        পাঠানোর একাউন্ট নিচে তুলে দিলাম। প্লিজ এগিয়ে আসুন।
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <p>Contact (Bkash personal available): 01736-211755 </p>
        <p>
          Account name: Md. Mohiuddin || Account no: 20502050204501308 || Islami
          Bank, Dhanmondhi Branch
        </p>
      </div>
    ),
    projectFbProfile: `https://fb.com/profile.php?id=100005435299487`,
    projectOrgName: "মারকাজুল লুগাতিল আরাবিয়া",
    projectOrg: { _ID: "sdqme023", name: "মারকাজুল লুগাতিল আরাবিয়া" },
    projectCreated: "February 2022",
    projectTag: "আরবি, Madrasa",
    projectLink:
      "https://fb.com/permalink.php?story_fbid=1812869622237530&id=100005435299487",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqmet009.1",
    projectImg: ummulmumenincover,
    slug: "ummul-mumenin",
    projectHeading: "উন্মুল মোমেনিন আইডিয়াল বালিকা মাদরাসা",
    projectDetails: (
      <div>
        <h4 className="mb-4">
          উন্মুল মোমেনিন আইডিয়াল বালিকা মাদ্ররাসা তাহফিজুল কুরআন ও এতিমখানা
          <br />
          ভাদালিয়া, উত্তর জলদি, বাশখালী পৌরসভা, চট্টগ্রাম স্থাপিতঃ ২০১৯ইং
        </h4>
        <p>
          আদর্শ মাতৃজাতি গঠনের একটি বিশ্বস্থ ইসলামী শিক্ষা প্রতিষ্ঠান.........
        </p>
        <p>
          * হিফজ ও নাজেরার পাশাপাশি প্রয়োজনীয় বাংলা, গণিত ও ইংরেজির পাঠদান ।
          সুন্দর হস্তলিপি আয়ত্বাকরণ ।
        </p>
        <p>আবাসিক • অনাবাসিক • ডে-কেয়ার</p>
        <p>
          নাজেরা বিভাগের বৈশিষ্ঠ্য :
          <br />
          * অভিজ্ঞ হাফেজার মাধ্যমে পাঠদান। অভিনব পদ্ধতিতে অতি অল্প সময়ে হিফজ
          সমাপ্তকরণ। বিখ্যাত কারীদের তিলাওয়াত শুনানোরব্যবস্থা। সুবিজ্ঞ কারীর
          মাধ্যমে বিশুদ্ধ ও সুন্দর তিলাওয়াতের প্রশিক্ষণ ।
          <br />
          * প্রতি মাসে পরিক্ষা ও মেধাবীদের বাছাই করে পুরস্কার প্রদান ।
          <br />* তালিম ও তারবিয়াতের গুরুত্ব প্রদান।
        </p>
        <p>
          হিফজ বিভাগের বৈশিষ্ঠ্যঃ
          <br />
          * বিশুদ্ধভাবে কুরআন পঠনের দক্ষতা অর্জন ।
          <br />
          * বিশেষ বিশেষ সূরা মুখস্থকরণ ।
          <br />
          * অযু-নামাজের মাসআলাসহ দৈনন্দিন মাসনূন দোয়াসমূহ মুখস্থ করণ ।
          <br />* প্রতিষ্ঠানের ছাত্রী ও শিক্ষিকার জন্য বিশুদ্ধ বাংলা ভাষায়
          কথোপকথন বাধ্যতামূলক ।
        </p>
        <p>
          আমরা চাই :
          <br />• একজন শিক্ষার্থী শিক্ষাগত যোগ্যতার পাশাপাশি চারিত্রিক
          সৌন্দর্য্য গঠনের মাধ্যমে বেড়ে উঠবে ।
          <br />• দ্বীনি দাওয়াত ও সমাজ সেবায় ব্রতী হয়ে উঠবে ।
          <br />• কুরআন ও সুন্নাহ অনুযায়ী নিজেও আমল করবে এবং অপরকেও তাতে
          উদ্বুদ্ধ করবে।
        </p>

        <p>পর্যায়ক্রমে কিতাব বিভাগ (দশম শ্রেণি তথা হেদায়া পর্যন্ত)</p>
        <p>সিসি ক্যামেরা দ্বারা সর্বক্ষণ নজরদারী</p>
        <div className="mt-4">
          <p>
            যোগাযোগ: জলদী মিয়ার বাজার থেকে সিএনজি ও রিক্সা যোগে ভাদালিয়া বড়
            মাদ্রাসা সংলগ্ন মহিলা মাদ্রাসা।
          </p>
          <p>মাওলানা মাহমুদ নোমানী, পরিচালক আলাপনিঃ ০১৮২৩-৯১৮৬৩৭</p>
          <p className="fw-bold">
            আমরা জাতিকে ‘সুশিক্ষিত মা’ উপহার দিতে চাই....
          </p>
        </div>
        <img
          src={ummulmumeninliflet}
          alt="ummulmumeninliflet"
          className="w-50 mb-5"
        />
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <p>Bkash personal: 01823-918637 </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/ataullahrafii`,
    projectOrgName: "বালিকা মাদরাসা ও এতিমখানা",
    projectOrg: {
      _ID: "sdqmet009",
      name: "উন্মুল মোমেনিন আইডিয়াল বালিকা মাদরাসা তাহফিজুল কুরআন ও এতিমখানা",
    },
    projectCreated: "Sep 2022",
    projectTag: "মহিলা ইয়াতিমখানা, Madrasa",
    projectLink: "https://sadaqahmadeeasy.com/madrasa/ummul-mumenin",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqmet008.1",
  //    projectImg: bahrululum,
  //    projectHeading: "বাহরুল উলুম ইসলামিয়া মাদরাসা ও এতিম খানা",
  //    slug: "bahrul-ulum",
  //    projectDetails: (
  //      <div>
  //        আসসালামু আলাইকুম ওয়া রহমাতুল্লাহি ওয়া বারকাতুহু
  //        <br />
  //        <br />
  //        <p>
  //          কুরবানীর ঈদের পরপর লক্ষীপুর গিয়েছিলাম সফরে, সাথে ছিল জুলফিকার এবং
  //          শান্তু ভাই। কাওসার ভাই একটা মাদ্রাসা ভিজিট করতে নিয়ে গেলেন, শহর থেকে
  //          কিছুটা দূরে, ভবানীগঞ্জ নামে এক এলাকায়। অনেকগুলো টিনের ঘর মিলে একটা
  //          মাদ্রাসা, সামনে একটা পুকুর, হৈচৈবিহীন জায়গা। মাদ্রাসার পাশেই কয়েক বিঘা
  //          জমি আছে, মুহতামিম সাহেবের স্বপ্ন সেগুলো কেনার ব্যবস্থা হলে বড় আকারে
  //          মাদ্রাসা এক্সপ্যান্ড করা, হয়তো আল্লাহ তৌফিক দিলে ভবিষ্যতে হবে। আমাদের
  //          অনেক আপ্যায়নও করালেন।
  //        </p>
  //        <p>
  //          বর্তমানে ছাত্রসংখ্যা ৩৮০, উস্তাদ ১৭ জন, বাবুর্চি ২ জন। এখন স্থান
  //          সংকুলন না হওয়ায় একটা ঘরের ২ পাশ বাড়াচ্ছে। আরো একটা নতুন ঘরের প্রয়োজন,
  //          এর পর ইনশাআল্লাহ আগামী ২ বছর কোন ঘর লাগবে না। টিনের যে স্ট্রাকচারে
  //          আছে, সেভাবে একটা নতুন ভবনের জন্য ১লাখ এবং ২ টা ভবনের বর্ধিত অংশের
  //          ফ্লোর ঢালাইয়ের জন্য আনুমানিক ৪০০০০ টাকা প্রয়োজন।{" "}
  //        </p>
  //        <p>
  //          যদিও সবাই জানেন, তাও আবার উল্লেখ করছি, মাদ্রাসায় সাদাক্বা করা
  //          সাদক্বায়ে জারিয়ার উৎস হিসেবে ওয়ান অফ দি বেস্ট। এখান থেকে যত তলিবুল
  //          ইলম, আলেম বের হবে, তাদের সব খেদমতের সাওয়াবের ভাগ পাওয়া যায় এবং এটা
  //          অনেকটা গাছের মত ছড়িয়ে পড়তে থাকবে।{" "}
  //        </p>
  //        <p>
  //          ১ লক্ষ ৪০ হাজার মাত্র, আমরা অল্প অল্প করে দিলেও খুবই তাড়াতাড়ি হয়ে
  //          যাওয়া সম্ভব, আল্লাহ আমাদের হাত প্রসারিত করুন। প্রত্যাশিত অর্থ উঠলে
  //          প্রজেক্ট ক্লোজ করে দেয়া হবে ইনশা আল্লাহ।{" "}
  //        </p>
  //        <p></p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <p> bKash Number: 01725-687972 (মুহতামিম সাহেব )</p>
  //        <p> bKash Number: 01711-074688 (কাওসার ভাই ) </p>
  //        <p> Nagad Number: 01711-074688 </p>
  //        <p> Rocket Number: 01711074688 5 </p>
  //        <hr />
  //        <h4 className="mb-4">মারকাযে অনুদান পাঠানোর মাধ্যম:</h4>
  //        <p>A/C Name: Bahrul Ulum Islamia Madrasha &Yeatim Khana </p>
  //        <p>Bank A/C Number: 20501900205102107 </p>
  //        <p className="mb-4"> Bank Name: Islami Bank Bangladesh ltd.</p>
  //        <p>Branch: Lakshmipur Br.</p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/mahbub687`,
  //    projectOrgName: "Bahrul Ulum Islamia Madrasha",
  //    projectOrg: { _ID: "sdqmet008", name: "Bahrul Ulum Islamia Madrasha" },
  //    projectCreated: "August 2022",
  //    projectTag: "সাদক্বায়ে জারিয়া, Madrasa",
  //    projectLink:
  //      "https://www.facebook.com/photo?fbid=10159785297527110&set=pcb.10159785301267110",
  //    get getURL() {
  //      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
  //    },
  //  },

  {
    _ID: "sdqmet006.1",
    projectImg: maktabPonchogor,
    projectHeading: "ফিতনাময় এলাকায় মকতব",
    slug: "maktab-panchagarh",
    projectDetails: (
      <div>
        <p>
          এ বছর জানুয়ারিতে আমরা পঞ্চগড়ে মকতব প্রতিষ্ঠার কথা বলেছিলাম। মকতবগুলো
          পঞ্চগড় সদরে কাদিয়ানী ফিতনাময় এলাকা ঘিরে করার চেষ্টা করা হয়েছে
          ইনশাআল্লাহ। প্রাথমিকভাবে আমরা ১০ টা মকতব চালাতে চাই। ৫ টা ছেলেদের। ৫
          টা মেয়েদের। ছেলেদের মকতব হবে মসজিদে। শিক্ষক হবেন পুরুষ। মেয়েদের মকতব
          হবে কোনো বাসায়। শিক্ষিকা হবেন নারী।
        </p>
        <p>
          এ ব্যাপারে আব্দুল মালেক সাহেব হুজুরের পাঠানো দাঈ ও পঞ্চগড় সোনাচান্দি
          গ্রামের মুআল্লিম আহমাদ তালেব ভাইয়ের সাথে আমরা বসে পরিকল্পনা সাজিয়েছি।
          ওনি ছেলেদের জন্য মসজিদ, মেয়েদের জন্য ঘর এবং শিক্ষক শিক্ষিকা বাছাই
          করেছেন। আলহামদুলিল্লাহ
        </p>
        <p>
          এমনিতেও এই রমজানের পর আমি মাদরাসার দায়িত্ব অন্য একজনকে বুঝিয়ে দিয়ে
          মকতব প্রকল্পে নামার চিন্তা করেছি। এই মুহুর্তে আমরা আমাদের মহল্লায় একটি
          মকতব চালাচ্ছি। এই মকতবের জন্য বাজেট ৫ হাজার।
          <br />
          ৩ হাজার টাকা উস্তাদকে হাদিয়া দেয়া হয়।
          <br />
          ১ হাজার টাকা মসজিদে বিদ্যুৎ ও পানির জন্য দেয়া হয়।
          <br />
          ১ হাজার টাকা দিয়ে বাচ্চাদের মাঝে মাঝে এটা সেটা খাওয়ানো হয়।
          <br />
          গত ৩১ ডিসেম্বর মকতবের সকল বাচ্চাকে আমরা পুরস্কৃত করেছি পুরস্কারে
          তাদেরকে রেহাল, কুরআন, বই, মগ ও চকলেট ইত্যাদি প্রদান করা হয়।
        </p>

        <div>
          <p>ফিতনাময় এলাকাগুলোতে যেসব বিষয়ে গুরুত্ব দেয়া হবে-</p>
          <p>১. তাজবীদ</p>
          <p>২. কায়দা/আমপারা/কুরআন</p>
          <p>৩. আকিদা (তাওহীদ, রিসালাত, খতমে নবুওয়াত, শরীয়ত ও আখিরাত)</p>
          <p>৪. ফারাইয</p>
          <p>৫. মাসাঈল</p>
          <p>৬. হাদিস</p>
          <p>৭. দুআ </p>
          <p>৮. আদব </p>
          <p>৯. কবিরা গুনাহ </p>
          <p>১০. হারামের পরিচয়</p>
        </div>

        <p>
          যেখানেই মকতব চালানো হোক, ৪/৫ হাজার টাকার কমে চালানো মুশকিল। কারণ, একজন
          শিক্ষক/শিক্ষিকাকেই ৩ হাজার টাকা সম্মানী দিতে হয়। এরপরও কিছু খরচ থাকে।
          তাই দশটা মকতব চালাতে মাসে ৪০-৫০ হাজার টাকা লাগে।{" "}
        </p>

        <p>
          এক্ষেত্রে সকলের দুআ ও সহযোগিতা কাম্য।
          <br />
          এমন দশজন ব্যক্তি বা দশ টা ফ্যামিলি হলে ভালো হয়, যারা এক একটি মকতবের
          দায়িত্ব নেবেন। ইতিমধ্যে আমার এক বড় ভাই একটি মকতবের দায়িত্ব গ্রহণ
          করেছেন। অবশ্য কেউ চাইলে এককালীন বা মাসিক ভিত্তিতে এই আমলে শরীক থাকতে
          পারেন। তাছাড়া মাসিক খরচের বাইরেও বাচ্চাদের বই, খাতা, পরীক্ষা ও
          পুরস্কার ইত্যাদিতে সারাবছর বেশ কিছু খরচের ব্যাপার আছে।
        </p>
        <p>
          উল্লেখ্য যে, ছাত্রদের জন্য মাথাপিছু বেতন/খরচ নির্ধারণ করে দরিদ্র
          ছাত্রদের জন্য যাকাতের টাকা গ্রহণ করা যায়। তবে যাকাতের টাকা এখনই
          ব্যবহার করা সম্ভব হবে না। রমযানের পর মাসে মাসে ধাপে ধাপে ব্যবহার করা
          হবে।{" "}
        </p>
        <p>
          যারা কাদিয়ানী ও মিশনারী ফিতনা কবলিত এলাকার মুসলিম শিশুদের জন্য দীনি
          শিক্ষার এই প্রকল্পে শরিক হতে চান তারা রেফারারা ফেসবুক আইডির সাথে
          যোগাযোগ করতে পারেন।
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4 fw-bold">
          প্রকল্পের জন্য বিকাশ নাম্বারঃ 01311338237{" "}
        </h4>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/aysb.82`,
    projectOrgName: "আহমাদ ইউসুফ শরীফ",
    projectOrg: { _ID: "sdqmet006", name: "আহমাদ ইউসুফ শরীফ" },
    projectCreated: "April 2022",
    projectTag: "Maktab, Sadaqah",
    projectLink: "https://www.facebook.com/aysb.82/posts/715797976129692",
  },

  //  {
  //    _ID: "sdqmet007.1",
  //    projectImg: darularkam,
  //    projectHeading: "দারুল আরকাম ওয়াল ইসলামিয়া",
  //    slug: "darul-arkam-wal-islamia",
  //    projectDetails: (
  //      <div>
  //        <h4>সদকায়ে জারিয়ায় অংশগ্রহণ করুনঃ</h4>
  //        <p>
  //          পবিত্র এই রমজান মাস মুসলিম উম্মাহর জন্য অতি গুরুত্বপূর্ণ একটি বরকতময়
  //          মাস। এই মাস আসলে নবীজি সল্লাল্লাহু আলাইহি ওয়াসাল্লামের দানের হাত
  //          তুলনামূলকভাবে অতি দ্রুত গতিতে বৃদ্ধি পেত।
  //        </p>
  //        <p>
  //          হজরত আবদুল্লাহ ইবনে আব্বাস (রাদি.) বলেন, রাসুলুল্লাহ (সল্লাল্লাহু
  //          আলাইহি ওয়াসাল্লাম) মানুষের মধ্যে সবচেয়ে কল্যাণকামী ও শ্রেষ্ঠ দাতা
  //          ছিলেন, আর রমজান এলে তিনি সবচেয়ে বেশি দান করতেন। জিবরাঈল (আলাইহিস
  //          সালাম) এর আগমন হলে তিনি প্রবাহিত বাতাসের মতো দান–খয়রাত করতেন। [বুখারী
  //          : ৩৫৫৪; মুসলিম: ২৩০৮]
  //        </p>
  //        <p>
  //          দান সাদাকাহর আলোচনায় আল্লাহ তা'আলা বলেন, ‘যদি তোমরা দান প্রকাশ্যে করো
  //          তবে তা উত্তম; আর যদি তা গোপনে করো এবং অভাবীদের দাও, তবে তা তোমাদের
  //          জন্য শ্রেয়। এর মাধ্যমে আল্লাহ তোমাদের মন্দগুলো মোচন করে দেবেন। তোমরা
  //          যা করো আল্লাহ তা অবগত আছেন।’ [সুরা-২ বাকারা: ২৭১]
  //        </p>
  //        <p>
  //          রাসূলুল্লাহ (সল্লাল্লাহু আলাইহি ওয়াসাল্লাম) আরও বলেছেন, সদাকাহ গুনাহকে
  //          সেভাবে মিটিয়ে দেয়, যেভাবে পানি আগুনকে নিভিয়ে দেয়। [মুসনাদ আহমাদ :
  //          ২২১৩৩]
  //        </p>
  //        <p>
  //          দান-সাদাকা কিংবা যাকাত দানের মাধ্যমে সম্পদ কমেনা বরং বৃদ্ধি পায় ও
  //          বরকতময় হয় এবং আল্লাহ আযযা ওয়া জাল্লা দান-সাদাকাকে আল্লাহর কাছে উত্তম
  //          ঋণ দেওয়া হিসেবে আখ্যায়িত করেছেন। [সূরা বাকারাহ: ২৪৫, ২৭৬; সূরা
  //          হাদীস:১৮; সহীহ মুসলিম: ২৫৮৮]
  //        </p>
  //        <p>
  //          এছাড়াও রাসুলুল্লাহ (সল্লাল্লাহু আলাইহি ওয়াসাল্লাম) বলেছেন, ‘নিশ্চয়ই
  //          সদকা আল্লাহর ক্রোধকে প্রশমিত করে।’ [জামে তিরমিযী: ৬৬৪; সহীহ ইবনে
  //          হিব্বান : ৩৩০৯]
  //        </p>
  //        <p>
  //          কওমী মাদ্রাসার অর্থই হচ্ছে, জাতির মাদ্রাসা। জাতির প্রতি কওমী মাদ্রাসার
  //          যেরুপ হক রয়েছে কওমী মাদ্রাসার প্রতিও জাতির হক রয়েছে। জাতিকে দ্বীন
  //          শেখানো, তাদের নিকট দ্বীনের সঠিক ইলম পৌঁছানো, তাদের দ্বীনি চাহিদা পূরণ
  //          করা ও ইসলামের মুখলিস, দরদী, আদর্শ খাদেম তৈরি করা হচ্ছে কওমী মাদ্রাসার
  //          অন্যতম গুরুত্বপূর্ণ দায়িত্ব। কিন্তু এই দায়িত্ব আঞ্জাম দেওয়ার জন্য তারা
  //          ততক্ষণ পর্যন্ত না সক্ষম হবে যতক্ষণ পর্যন্ত মহান আল্লাহর সাহায্য ও
  //          জাতির লোকদের এই মহতী কাজে সার্বিকভাবে অংশগ্রহণ থাকবে। সেজন্য কওমী
  //          মাদ্রাসাকে সার্বিকভাবে সহায়তা-সহযোগিতা করাও জাতির আবশ্যক দায়িত্ব ও
  //          কর্তব্য।
  //        </p>
  //        <p>
  //          এরই ধারবাহিকতায় আমরা একটি কওমী মাদ্রাসার সাহায্যের জন্য আবেদন
  //          জানাচ্ছি। মাদরাসাটি বিগত দেড় বছর যাবত ঋণের বেড়াজালে আবদ্ধ রয়েছে। কতিপয়
  //          এতীম ও অসহায় ছাত্রদের বেতনও বহু মাস যাবত বকেয়া রয়েছে। অর্থের সংকোচনের
  //          কারণে সেসব শিক্ষার্থীরা বেতন ও পড়ালেখার খরচ চালাতে পারছেন না। এছাড়াও
  //          মাদরাসার কিতাব ফান্ডেও বেশ কিছু টাকার প্রয়োজন রয়েছে।
  //        </p>
  //        <p>
  //          সুতরাং সাদাকায়ে জারিয়াহর সাওয়াব হাসিলের উদ্দেশ্যে যথাসাধ্য তাওফীক
  //          অনুযায়ী আপনার/আপনাদের দান, সাদাকাহ, যাকাত, কাফফারা ও মানতের টাকা
  //          দ্বারা উক্ত মাদরাসাটির ঋণ পরিশোধে, কিতাব খরিদ করতে এবং হিফজ ও কিতাব
  //          বিভাগের মেধাবী এতীম, অসহায় শিক্ষার্থীদের সাহায্যের জন্য এগিয়ে আসুন।
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">
  //          দান-সাদাকাহ, যাকাত, কাফফারা ও মানতের টাকা পাঠানোর জন্য-
  //        </h4>
  //        <p>বিকাশ পার্সোনাল 01685653343</p>
  //        <p>নগদ পার্সোনাল 016856533432</p>
  //        <p>রকেট পার্সোনাল 01685425009</p>

  //        <hr />

  //        <p>ব্যাংক ডিটেইলস- </p>
  //        <p>DUTCH BANGLA BANK LIMITED </p>
  //        <p>HALISHAHAR BRANCH</p>
  //        <p>Mohammad Tanzim Ahmed</p>
  //        <p>Bank A/C no: 1651510142165</p>

  //        <p className="fw-bold">
  //          বিঃদ্রঃ বিকাশ, নগদ ও রকেটে টাকা পাঠালে রেফারেন্সে কোন খাতে দেওয়া হচ্ছে
  //          সেটি উল্লেখ করে দিলে ভাল হয়। যেমন, কেউ যাকাত খাতে দিলে রেফারেন্সে
  //          'যাকাত' লিখবে।
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/abdullah.almamun.35175633`,
  //    projectOrgName: "Abdullah Al Mamun",
  //    projectOrg: { _ID: "sdqmet007", name: "Abdullah Al Mamun" },
  //    projectCreated: "April 2022",
  //    projectTag: "Madrasa, Sadaqah",
  //    projectLink: "https://www.facebook.com/darularqamalislamiah",
  //  },

  {
    _ID: "sdqmet004.1",
    projectImg: jamiahasnain,
    projectHeading: "ঘূর্ণিঝড়ে লণ্ডভণ্ড হয়ে গেল",
    slug: "jamiatul-hasnain",
    projectDetails: (
      <div>
        <p>
          তিলে তিলে গড়ে উঠা একটি স্বপ্নের বাগান এভাবে আজ ঘূর্ণিঝড়ে লণ্ডভণ্ড
          হয়ে গেল৷ মাদরাসার পুরো ছাদ যমিনে নিয়ে ফেলেছে৷ লাখ লাখ টাকার কিতাব
          পূর্ণ ভিজে গেছে৷ ছাত্র-উস্তাদদের আসবাবের কথা বাদই দিলাম৷ রাতে থাকার
          ব্যবস্থা পর্যন্ত নেই এখন৷ এ অবস্থায় একটি মাদরাসার হালত কী দাঁড়াতে
          পারে একটু চিন্তা করলে শরীর শিউরে উঠে৷
        </p>
        <p>
          আল্লাহ তা'আলা হয়ত কর্তৃপক্ষের পরীক্ষা নিচ্ছেন৷ বড়দের থেকে শুনেছি
          মাদরাসার শুরুর অংশে মসিবত আসলে তা মাকবুল হওয়ার আলামত৷
        </p>
        <p>
          বলছিলাম জামিয়াতুল হাসানাইন ঢাকা'র কথা৷ দেওবন্দের মুরব্বিদের পরামর্শে
          এই প্রতিষ্ঠানটি শুরু করেছিলেন শ্রদ্ধেয় মারুফ মুজিব ভাই৷ মূলত এটি
          উচ্চতর উলূমুল হাদীস বিভাগ৷ মুরব্বিদের দুআর বরকতে অল্প সময়ে এইটুকু
          পর্যন্ত আসা৷{" "}
        </p>
        <p>
          আজ দুর্ঘটনার পর পর দারুল উলূম দেওবন্দের মুহতামিম ও শাইখুল হাদীস হযরতুল
          উস্তাদ আবুল কাসিম নুমানী হাফি. ও ইন্ডিয়ার ফিকহি বোর্ডের কর্ণধারদের
          একজন মুহতারম হযরত উবাইদুল্লাহ আসআদী সাহেব হাফি. কল করে ভরপুর দুআ
          করেছেন৷ মাদরাসার সামনের তরক্কীর আলামত এটি এমনটি বন্তব্য করেছেন৷{" "}
        </p>
        <p>
          মাদরাসার অর্থনৈতিক অবস্থাও অত ভাল নয়৷ মাত্র শুরু হবে নতুন উদ্যমে৷ এরই
          মাঝে এই পরীক্ষা! এমনিতেই অনেক টাকা ঋণ রয়েছে মাদরাসার৷ সকলের নিকট দুআর
          আবেদন আল্লাহ যেন এই প্রতিষ্ঠানকে কবুল করেন৷ সামনে খরচগুলোর ব্যবস্থা
          করে দেন৷ কর্তৃপক্ষকে ধৈর্য ধারণ করার তাওফীক দিন৷
        </p>

        <p>
          কোন ভাই সহযোগিতা করতে চাইলে Referrer এর ইনবক্সে যোগাযোগ করার আবেদন
          থাকল৷ সহযোগিতা করে অবশ্যই ইনবক্সে শেষ তিনটি নাম্বার ও দান কোন খাতের (
          সদকা,যাকাত,না সাধারণ দান ইত্যাদি) তা জানানোর অনুরোধ থাকল৷
        </p>
        <p>
          যাতায়াত ঠিকানা: টোটালিয়া পাড়া,শ্যামলাসী,সাভার, ঢাকা৷ মুহাম্মাদপুর
          বসিলা ব্রিজ থেকে ওয়াশটাওয়ার৷ সেখানা থেকে এককিলোমিটার৷
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">বিকাশ:</h4>
        <p>01638921090 (পার্সোনাল)</p>

        <hr />

        <hr />
        <p>Bank: ইসলামি ব্যাংক </p>
        <p>A/C No: 20507770206255121 </p>
        <p>A/C Name: Jamiatul hasnain Dhaka bangladesh</p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/profile.php?id=100005939839174`,
    projectOrgName: "Zubair Hanif",
    projectOrg: { _ID: "sdqmet004", name: "Zubair Hanif" },
    projectCreated: "April 2022",
    projectTag: "Zakat, Sadaqah",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=1922431914631451&id=100005939839174",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqme022.1",
    projectImg: mahadulIman,
    slug: "mahadul-iman",
    projectHeading: "মাহাদুল ঈমান চট্টগ্রাম",
    projectDetails: `সদকায়ে জারিয়া খাত: 
অনেকে না জানার কারণে পরিচিত হয়েও সদকায়ে অংশগ্রহণ করতে পারেন না তাই বলছি....
	আমাদের মাদ্রাসায় কয়েকটি সদকায়ে জারিয়ার খাত চালু আছে বর্তমানে। তার মধ্যে...
	১. এক নাম্বার হলো মাদ্রাসার লাইব্রেরি এবং কিতাব এর জন্য সদকা করা
	২. দুই নাম্বার হলো যে সব ছাত্ররা ইনকাম করে না কিন্তু ইলম অর্জন করতে চাই তাদের পড়ালেখার খরচ বহন করা ।
	ছবিতে: লাইব্রেরীর বর্তমান অবস্থা।`,
    projectContact: `Contact (Bkash personal available): 01706-880929`,
    projectOrgName: "মাহাদুল ঈমান চট্টগ্রাম",
    projectOrg: { _ID: "sdqme022", name: "মাহাদুল ঈমান চট্টগ্রাম" },
    projectCreated: "February 2022",
    projectTag: "সাদাকা, Madrasa",
    projectLink: "https://fb.com/Mahadul-Iman-Chittagong-108827701694350",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },
  {
    _ID: "sdqme007",
    projectImg: JamiyaHafizulUlum,
    slug: "jamia-hafezul-ulum",
    projectHeading: "মাদ্রাসা সংস্করণ",
    projectOrgName: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    projectDetails: `আলহামদুলিল্লাহ আমাদের জামিয়া হাফেজুল উলুম আল-ইসলামিয়ার 
অযু ও বাথরুমের কাজ শেষ করে , পুনরায় ৯জন শিক্ষক সহ - হিফজ ও নাজেরা বিভাগের আবাসিক ঘর এবং পাশাপাশি ২০০/২৫০ জন ছাত্র শিক্ষক নামাজ আদায় করতে পারে মতো মসজিদ নির্মাণ কাজ শুরু করতে যাচ্ছি 
মাটি লেভেলিং কাজ চলছে । এতে সহযোগিতা করে যাচ্ছেন এক্সপেক্টা অব বাংলাদেশ লিঃ,মিঃ  এর উর্ধ্বতন কর্মকর্তা ইন্জিনিয়ার জনাব মোঃ নাসির উদ্দীন দিদার ও 
রাজিব কুমার দাস
এজন্য আমরা সবাই কৃতজ্ঞ । দেশের ও জনগণের উন্নয়ন কামনা করছি
তাই দেশে বিদেশের দ্বীন প্রিয় সকল ভাইদের নিকট বিশেষ করে  দোয়ার আবেদন করছি
আল্লাহ তায়ালা যেন অত্র প্রতিষ্ঠানের যাবতীয় প্রয়োজন গায়েবী খাজানা থেকে পুরণ করে দেন ।
আমীন ইয়া রাব্বুল আলামীন
`,
    projectFbProfile1: `https://fb.com/profile.php?id=100077657504454`,
    projectFbProfile2: `https://fb.com/ahmedsagor.ctg`,
    projectContact: `বিকাশ (পার্সোনাল): 01814-292542 ||
যোগাযোগঃ 01739-871496 (মুফতি কামরুল ইসলাম - শিক্ষক অত্র প্রতিষ্ঠান)
`,
    projectOrg: {
      _ID: "sdqme007",
      name: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    },
    projectCreated: "Januray 2021",
    projectTag: "মাদ্রাসা, Education",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=3025836704336789&id=100007314665018",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },
];

export function getMadrasaList() {
  return MadrasaList;
}
