const ZakatProjectList = [
  {
    _ID: "sdqmez002.1",
    projectImg: "",
    projectHeading: "পার্বত্য অঞ্চলের মাদ্রাসায় যাকাত প্রদানের সুযোগ",
    slug: "tanbir-ahmed",
    projectDetails: `আপনার যাকাতের অর্থ পার্বত্য অঞ্চলের এসব প্রতিষ্ঠানে দিতে পারেন অনেক উপকার হবে। 
পার্বত্য অঞ্চলে আমাদের প্রতিষ্ঠিত মাদ্রাসাগুলোতে প্রায় ৯৫ ভাগ ছাত্র ছাত্রীরা ফ্রী পড়াশোনা করে। শিক্ষকদের বেতন মাসের পর মাস বাকি রয়েছে। 
রমজানে অনেকেই যাকাত দিয়ে থাকেন। আপনাদের মাদ্রাসার লিল্লাহ বোডিং আপনার সামর্থ্য অনুযায়ী যাকাত দিলে প্রতিষ্ঠান গুলো চালানো সহজ হয়।
স্থানঃ রাঙামাটি
`,
    projectContact: `বিকাশ, নগদ, রকেটঃ 01608-184511`,
    projectFbProfile: `https://fb.com/tanbir.ahmad.336`,
    projectOrgName: "Tanbir Ahmad",
    projectOrg: { _ID: "sdqmez002", name: "Tanbir Ahmad" },
    projectCreated: "April 2022",
    projectTag: "যাকাত, Zakat",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=504927377752358&id=100047052998545",
  },

  {
    _ID: "sdqmez003.1",
    projectImg: "",
    projectHeading: "যাকাত প্রজেক্ট ২০২২",
    slug: "zakat-saba-sanabil",
    projectDetails: (
      <div>
        <p>
          সুদূর বান্দরবানের পাহাড়ি নওমুসলিম যিনি প্রায় ৪০ দিন তাবলীগের এক সফরে
          ছিলেন দ্বীনের প্রাথমিক শিক্ষা অর্জনের জন্য। দুদিন আগে আমাদের কাছে তিনি
          ফোন দিয়ে জানালেন কিছু টাকা প্রয়োজন কলার ব্যবসা করবেন। টাকাটা আমরা
          চাইলে সাথে সাথে দিতে পারতাম কিন্তু উনি আসলে কাজে দক্ষ কিনা সেটা বোঝার
          জন্য উনাকে মাসিক চুক্তিতে এই কাজটি করতে দিয়েছি এবং বলেছি যদি সততার
          সাথে কাজ টি করতে পারে তাহলে উনাকে আমরা যাকাতের টাকা দিয়ে ব্যবসা করতে
          সহযোগিতা করব ইনশাআল্লাহ।
        </p>
        <p>
          কিছুদিন আগেও কুড়িগ্রামে আমাদের এক মাদ্রাসার ছাত্রীর বাবাকে একটি
          অটোরিকশা কিনে দেওয়া হয়েছিল অনেক যাচাই-বাছাইয়ের পর। বর্তমানে এখন
          পর্যন্ত তিনি সফলতার সাথে অটো রিক্সা চালিয়ে সংসার চালাচ্ছেন
          আলহামদুলিল্লাহ।
        </p>
        <p>
          দুইদিন আগেও এক ভাই জানালেন যে তার যাকাত বাবদ প্রায় ৭০ হাজাড় টাকা আছে
          সেটা উনি দিতে যাচ্ছেন এমন কাউকে চিনি একটা হালাল আয় রোজগার করতে পারবে
          সে টাকাটা দিয়ে। অনেকগুলো প্রশ্ন-উত্তরের ধাপ পেরিয়ে আমরা এক ভাইকে
          মোবাইলের অ্যাক্সেসরিজ ব্যবসা করার জন্য যাকাতের টাকাটা আজকে পাঠিয়ে
          দিয়েছি আলহামদুলিল্লাহ।
        </p>
        <p>
          আমাদের মাধ্যমে অনেকেই যাকাত এর অর্থ দিয়ে মানুষকে স্বাবলম্বী করার
          চেষ্টা করে থাকেন কিন্তু ঠিকমত যাচাই-বাছাই না করলে দেখা যায় আপনি যে
          টাকা দিয়ে কাউকে গরু কিনে দিয়েছেন সেই গরু দুইদিন পরে সে বিক্রি করে
          দিয়ে নিজের অন্য কোনো প্রয়োজন পূরণের চেষ্টা করছে। মূল কথা জিনিসটা
          সাস্টেইনেবল করা জরুরি যাতে ব্যবসার জন্য যে টাকাটা কাউকে দেয়া হয়েছে
          সেটা রোটেশন হতে পারে।
        </p>
        <p>
          বিগত তিন বছর ধরে Sab'a Sanabil Foundation যাকাত ফান্ড এর মাধ্যমে আমরা
          অনেক ভাই-বোনকে তার যোগ্যতা দক্ষতা এবং পূর্ব অভিজ্ঞতা বিষয়ক
          সাক্ষাতকার/প্রশ্ন-উত্তরের মাধ্যমে যোগ্য ব্যক্তিকে যোগ্য ব্যবসা করতে
          মূলধন দিয়ে সহযোগিতা করা হচ্ছে আলহামদুলিল্লাহ।
        </p>
        <div>
          <p>
            যেসব পরিবার বর্তমানে আমাদের যাকাত ফান্ড এর মাধ্যমে উপকৃত হচ্ছেন
            তাদের একটি লিস্ট দেয়া যাক:
          </p>
          <p>১. দ্বীনদার এতিম, অসহায় অথবা বিধবা পরিবার </p>
          <p> ২. পাহাড়ি নওমুসলিম </p>
          <p>৩. ঈসায়ী খ্রিষ্টান থেকে রিভার্টেড মুসলিম </p>
          <p>৪. আমাদের মাদ্রাসার দরিদ্র শিক্ষক </p>
          <p>৫. দ্বীনদার দরিদ্র ফ্যামিলি</p>
          <p>
            {" "}
            ৬. যে সকল দ্বীনী পরিবারে রমজানে তাদের বাবারা সাথে থাকতে পারছে না।
          </p>{" "}
          <p>৭. চরে বসবাসরত দরিদ্র মুসলিম </p>
          <p>৮. ঋণগ্রস্ত মুসলিম পরিবার </p>
          <p>৯. হালাল রিজিকের চেষ্টারত প্রাক্টিসিং মুসলিম </p>
          <p>১০. সূরা তাওবা ৬০ নম্বর আয়াতে উল্লেখিত খাত </p>
        </div>
        <p>
          আমাদের যাকাত প্রজেক্ট ১ম রমাদান থেকে ৩০তম রমাদান সহ সারা বছর চলমান
          থাকবে ইন শা আল্লাহ। তাই নিচের একাউন্ট গুলোতে আপনার যাকাতের অর্থ জমা
          করে আপনার কিছু অসহায় এতিম মুসলিম ভাই বোনদের হক আদায়ে সহযোগিতা করতে
          পারেন ইনশাআল্লাহ।
        </p>
        <p>
          "আল্লাহ যাকে সম্পদ দান করেছেন, অথচ সে তার যাকাত আদায় করেনি, ক্বিয়ামতের
          দিন তার সেই সম্পদকে টেকো মাথা বিষাক্ত সাপে রূপান্তরিত করা হবে। যার
          দু’চোখে দু’টি কালো বিন্দু থাকবে। ক্বিয়ামতের দিন ঐ সাপ তার গলা পেঁচিয়ে
          ধরবে এবং দু’চোয়াল কামড়ে ধরে বলতে থাকবে, আমি তোমার সঞ্চিত ধন। আমি তোমার
          সঞ্চিত ধন" (বুখারী, মিশকাত হা/১৭৭৪ ‘যাকাত’ অধ্যায়)।
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">যাকাত ফান্ডের একাউন্ট নাম্বারঃ</h4>
        <p>Bank: Islami Bank Bangladesh Ltd. </p>
        <p>A/C No(Current): 20503550100226818 </p>
        <p>A/C Name: Saba Sanabil Jakat</p>
        <p> Branch: Bhurungamari, Kurigram. </p>
        <p className="mb-4"> Routing: 125490108</p>
        <hr />
        <p>bKash Personal: 01778-418210 </p>
        <p> Rocket personal: 01778418210-4 </p>
        <p>Nagad Personal: 01778-418210</p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/SabaSanabilFoundation/`,
    projectOrgName: "Sab'a Sanabil Foundation",
    projectOrg: { _ID: "sdqmez003", name: "Sab'a Sanabil Foundation" },
    projectCreated: "April 2022",
    projectTag: "যাকাত, Zakat",
    projectLink:
      "https://www.facebook.com/SabaSanabilFoundation/posts/2005726969608032",
  },

  //  {
  //    _ID: "sdqmez005.1",
  //    projectImg: zakatSabit,
  //    projectHeading: "স্বাবলম্বী প্রজেক্ট by Sabit Ahmed",
  //    slug: "shabolombi-mohila",
  //    projectDetails: (
  //      <div>
  //        <p>
  //          কথায় আছে অভাগা যেদিকে যায় সাগর শুকিয়ে যায়। কথাটার ব্যাখ্য আমি জানি
  //          না। এই মহিলার ২ বার বিবাহ হয়, ২ বারই স্বামী মারা যায়।
  //        </p>
  //        <p>
  //          পর্দার খেলাপ হবে বিধায় ক্লাস ৫ পর্যন্ত পড়াশোনার পরে আর পড়ান নি
  //          বাবা। সাথে অর্থের অভাব তো ছিলই। বিবাহ উপযুক্ত হলে ভালো দেখে একটা বিবাহ
  //          দিলেন। কয়েক বছর ঘর সংসার করার পরে স্বামীটা মারা গেল। নেমে আসল কষ্ট
  //          অনেকদিন পরে আবার বিবাহ দেওয়া হলো তাকে। সেখানে মহিলাটি ৩ টি মেয়ে । এই
  //          স্বামীটিও মারা গেল হঠাৎ। তিনটি মেয়ে নিয়ে অকুল সমুদ্রে পরে গেল
  //          মহিলাটি। স্বামীর মাত্র ৭ কাটা জমি মানে ১৪/১৫ শতক জমি আছে মাত্র। স্বামী
  //          মারা যাবার পরে তিল তিল করে মেয়েদের মানুষ করছেন পর্দা ঠিক রেখে।
  //        </p>
  //        <p>
  //          বড় মেয়েকে বিবাহ দিয়েছেন। মেজ মেয়ে ও ছোট মেয়ে নিয়ে চলছে দিন খুবই
  //          কষ্টে। ইনকামের কোন মানুষ নাই তার। মেজ মেয়েকে দর্জি( সেলাই মেশিনের কাজ
  //          শিখিয়েছেন)। তিনি পর্দা করেন বিধায় অনেক কিছু বাড়ির বাইরে গিয়ে করতে
  //          পারেন না।
  //        </p>
  //        <p>
  //          তার জন্য এখন এমন কিছু করে দেওয়া যাতে তিনি আল্লাহর রহমতে সাবলম্বী হয়ে
  //          যান।
  //        </p>

  //        <div>
  //          <p>যা যা প্রয়োজন:</p>
  //          <p>
  //            ১. ১০,০০০ টাকা করে ৪ টা ছাগল (৩ টা ধারি ছাগল ১ টা খাসি ছাগল) মোট
  //            ৪০,০০০ টাকা।{" "}
  //          </p>
  //          <p> ২. একটা সেলাই মেশিন ৭,৫০০ টাকা ( সিঙ্গার কোম্পানির) </p>
  //          <p>৩. ৬/৭ টা দেশি মুরগি ( ৪০০ টাকা কেজি) ২,৮০০ টাকা। </p>
  //          <p>
  //            ৪. ৩ মাসের খাবার খরচ ( প্রতি মাসে আনুমানিক ৫,০০০ টাকা) মোট ১৫,০০০
  //            টাকা মাত্র।{" "}
  //          </p>
  //          <p>সব মিলিয়ে ৬৫,০০০ টাকা মত একটা বাজেট প্রয়োজন।</p>
  //          <p className="fw-bold">
  //            এই মহিলাটি যাকাতের পরিপূর্ণ হকদার। যে যে খাতে দান করা যায় তিনি সকল
  //            দিক থেকে হকদার। আপনার সামর্থ্য অনুযায়ী সহযোগিতাও করুন।
  //          </p>
  //        </div>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">বিকাশ:</h4>
  //        <p>01709074259(পার্সোনাল)</p>
  //        <p>01977516335 (নগদ) </p>
  //        <p>01877516336+7(রকেট) </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/sabit.ahmed.7547`,
  //    projectOrgName: "Sabit Ahmed",
  //    projectOrg: { _ID: "sdqmez005", name: "Sabit Ahmed" },
  //    projectCreated: "April 2022",
  //    projectTag: "স্বাবলম্বী, Zakat",
  //    projectLink:
  //      "https://www.facebook.com/sabit.ahmed.7547/posts/1678036112557384",
  //  },
];

export function getZakatProjectList() {
  return ZakatProjectList;
}
