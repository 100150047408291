import sylhetbarakah from "../img/sylhetbarakah.jpeg";
import sylhetflood1 from "../img/sylhetflood1.jpeg";
import sylhetflood2 from "../img/sylhetflood2.jpeg";
//import sylhetflood3 from "../img/sylhetflood3.jpeg";
import sylhetflood4 from "../img/sylhetflood4.jpeg";
import sylhetsabas from "../img/sylhetsabas.jpeg";
import sylhetghonaighat from "../img/sylhetghonaighat.jpeg";
import sylhetfloodsiyanah from "../img/sylhetfloodsiyanah.jpeg";
//import sylhetmedmunir1 from "../img/sylhetmedmunir1.jpeg";
//import sylhetmedmunir2 from "../img/sylhetmedmunir2.jpeg";
import sylhetmedmanahil1 from "../img/sylhetmedmanahil1.jpeg";
import sylhetmedmanahil2 from "../img/sylhetmedmanahil2.jpeg";
//import sylhetsiyanaheng from "../img/sylhetsiyanaheng.jpeg";

//last serial: 38

const SylhetProjects = [
  {
    _ID: "sdqme034.1",
    projectImg: sylhetfloodsiyanah,
    projectHeading: "ভিটেমাটি বিহীনদের ঘরে ফেরাতে পুনর্বাসন প্রকল্প",
    slug: "sylhet-resettle-sianah",
    projectDetails: (
      <div>
        <h3>
          সিলেটে স্মরণকালের ভয়াবহ বন্যায় বিপর্যস্ত ভিটেমাটি বিহীন মানুষকে ঘরে
          ফেরাতে সিয়ানাহ ট্রাস্ট - Sianah Trust এর পুনর্বাসন প্রকল্প
        </h3>
        <p className="fw-bold mt-5">আমাদের কর্মসূচীঃ </p>
        <p>- সম্পূর্ণ ঘর স্রোতে ভাসিয়ে নিয়েছে, এমন ঘর পুনর্নিমাণ </p>
        <p>- আংশিক ভেঙ্গে যাওয়া ঘর সংস্কারকরণ</p>
        <p>- নির্মাণ সামগ্রী ক্রয় করে হস্তান্তর </p>
        <p>- নগদ অর্থ প্রদান</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <div className="mb-5">
          <h4 className="mb-3">BKASH PERSONAL (Send Money): </h4>
          {/*<p>01717 953058 - মুফতি জিয়াউর রহমান - পরিচালক </p>*/}
          <p>01710 446038 - MD ABDUL HAMID SAKIB</p>
          <p>01765 204547 - ASAD UDDIN </p>
          <p>01741 585040 - ABDULLAH AL MONSUR </p>
        </div>

        <div className="mb-5">
          <h4 className="mb-3">ROCKET - PERSONAL: </h4>
          <p>01724 344251-8</p>
        </div>

        <div className="mb-5">
          <h4 className="mb-3">NAGAD - PERSONAL: </h4>
          <p>01724 344251 - MD SAIFUR RAHMAN</p>
        </div>

        <hr />
        <h4 className="mb-4">BANK-1</h4>
        <p>Bank: Islami Bank Bangladesh Limited</p>
        <p>Account Name : Ziaur Rahman </p>
        <p>MSA Account No : 2050 1660 2034 76507 </p>
        <p>Branch: Ambarkhana, Sylhet</p>
        <br />
        <br />
        <h4 className="mb-4">BANK-2</h4>
        <p>Bank: Islami Bank Bangladesh Limited</p>
        <p>Account Name : Asad Uddin </p>
        <p>Account No : 2050 1660 2032 11311 </p>
        <p>Branch: Ambarkhana, Sylhet</p>

        <hr />
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/Zia.Eshona`,
    projectOrgName: "সিয়ানাহ ট্রাস্ট",
    projectOrg: { _ID: "sdqme034", name: "সিয়ানাহ ট্রাস্ট" },
    projectCreated: "June 2022",
    projectTag: "বন্যা, Resettlement",
    projectLink:
      "https://www.facebook.com/photo?fbid=2208291415993992&set=a.105824529574035",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqme036.1",
  //    projectImg: sylhetflood4,
  //    projectHeading: "ইমারজেন্সি বন্যা প্রজেক্ট - সিলেট",
  //    slug: "sylhet-saba-sanabil",
  //    projectDetails: (
  //      <div>
  //        <h4 className="mb-3">
  //          <u> ১৭ জুন, ইমারজেন্সি বন্যা প্রজেক্ট - সিলেট</u>
  //        </h4>
  //        <p>
  //          গতকয়েকদিন যাবত টানা ভারি বৃষ্টি ও উজানের পানি তে সিলেটের কয়েকটি
  //          উপজেলায় এখন আবার ও ভয়াবহ বন্যা পরিস্থিতি বিরাজমান। কমেন্টের কিছু ভিডিও
  //          দেখলেই আশা করি বুঝতে পারবেন কি পরিস্থিত চলছে। স্বরণকালে এরকম বন্যা কেউ
  //          দেখেছেন কিনা সন্দেহ।
  //        </p>
  //        <img src={sylhetsabas} alt="sylhetsabasanabil" className="w-50 mb-5" />
  //        <p>
  //          এই মুহূর্তে বেশিরভাগ এলাকায় শুকনো খাবার ও বিশুদ্ধ পানির আর্জেন্ট
  //          প্রয়োজন। আমাদের আগের লিস্ট অনুযায়ী আমরা শুকনো খাবার পৌছে দেবার চেষ্টা
  //          করব ইন শা আল্লাহ। এর মধ্যে সিলেটের আর ও কিছু স্বেচ্ছাসেবী সংগঠনের
  //          সাথেও আমাদের আলোচনা চলছে, সম্মিলিত ভাবে কাজ করার ইন শা আল্লাহ।
  //        </p>

  //        <p>
  //          এরমধ্যেই টানা প্রায় ৬-৭ সপ্তাহ যাবত আমাদের ত্রাণ বিতরণ কার্যক্রম চলছে।
  //          গতকালকেও নগদ অর্থ বিতরণ করা হয়েছে বন্যার্তদের মধ্যে (কমেন্ট বক্সে ছবি
  //          দেয়া হয়েছে)। ফান্ড ও এখন খুব বেশি অবশিষ্ট নেই। আর্জেন্ট শুকনো খাবার ও
  //          পানি প্রয়োজন পড়বে। শুকনো খাবার ও পুনর্বাসনে যারা এখনও অংশ নিতে চান
  //          তারা নিচের একাউন্ট গুলোতে সাদাকাহ পাঠাতে পারবেন ইনশাআল্লাহ।
  //        </p>
  //        <b>নোটঃ</b>
  //        <br />
  //        <b> ১. কুরবানি ও বন্যা প্রজেক্টের একাউন্ট নম্বর আলাদা। </b>
  //        <br />
  //        <b>২. আপাতত আমরা যাকাত নিচ্ছি না</b>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <div className="mb-5">
  //          <h4>বিকাশ: 01742824468 </h4>
  //          <h4>নগদ: 01742824468 </h4>
  //          <h4>রকেট: 01742824468-7</h4>
  //          <hr />
  //          <p>
  //            Bank: Islami Bank Bangladesh Ltd.
  //            <br />
  //            A/C Name: ILM SHARE
  //            <br /> Current A/C No: 20506990100016807
  //            <br />
  //            Branch: Bhurungamari, Kurigram.
  //            <br />
  //            Routing: 125490108
  //          </p>
  //        </div>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/SabaSanabilFoundation`,
  //    projectOrgName: "Sab'a Sanabil Foundation",
  //    projectOrg: { _ID: "sdqme036", name: "Sab'a Sanabil Foundation" },
  //    projectCreated: "June 2022",
  //    projectTag: "বন্যা, Sylhet",
  //    projectLink:
  //      "https://www.facebook.com/SabaSanabilFoundation/posts/2058756064305122",
  //  },

  //  {
  //    _ID: "sdqme032.1",
  //    projectImg: sylhetflood1,
  //    projectHeading: "সিলেটের বন্যা কবলিত পরিবারে আবারো সাহায্য",
  //    slug: "sylhet-flood-barakah",
  //    projectDetails: (
  //      <div>
  //        <h3>
  //          আসসালামু আলাইকুম। আল-বারাকাহ ফাউন্ডেশন কর্তৃক সিলেটের বন্যা কবলিত
  //          পরিবারগুলোকে সাহায্যের উদ্যোগ নেয়া হয়েছে।
  //        </h3>
  //        <p>
  //          কয়েকদিনের ব্যবধানে সিলেটবাসী আবারো বন্যায় আক্রান্ত—ঘরবাড়ি, স্কুল-কলেজ,
  //          মাদরাসা বন্যার পানিতে তলিয়ে যাচ্ছে। এছাড়া মুষলধারে বৃষ্টিতে চরম বিপাকে
  //          বন্যাক্রান্ত পরিবার। তাই আবারো আল-বারাকাহ ফাউন্ডেশন কর্তৃক সিলেটের
  //          বন্যা কবলিত পরিবারগুলোকে সাহায্যের উদ্যোগ নেয়া হয়েছে। বন্যার্তদের
  //          প্রয়োজনীয় খাদ্যসামগ্রী, ঔষধ ও নগদ অর্থ বিতরণ করা হবে, ইনশা আল্লাহ। এতে
  //          আপনাদের দুআ, পরামর্শ এবং সাহায্য একান্ত কাম্য। জাযাকুমুল্লাহ খাইরান।
  //        </p>
  //        <img
  //          src={sylhetbarakah}
  //          alt="sylhetflood1"
  //          className="w-25 mt-3 mb-3"
  //        />
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">বিকাশ, নগদ: 01580357441</h4>

  //        <hr />

  //        <p>1) Bank: Dutch Bangla Bank, Amborkhana, Sylhet </p>
  //        <p>A/C No: 201-151-26430 </p>
  //        <p>A/C Name: Mohammad Zakaria Raihan</p>
  //        <hr />
  //        <p>2) Bank: Pubali bank, Islamic Banking Window </p>
  //        <p>A/C No: 3487901001986 </p>
  //        <p>A/C Name: CHOWDHURY MOHIUL MOSTOFA FARHAN</p>

  //        <hr />
  //        <p>
  //          "যারা আল্লাহর পথে ব্যয় করে, তাদের উপমা একটি শস্য-বীজের মত, যা থেকে
  //          সাতটি শীষ জন্মে, প্রতিটি শীষে থাকে একশত শস্য-দানা। আর আল্লাহ যার জন্য
  //          ইচ্ছা বহুগুণে বৃদ্ধি করে দেন। আল্লাহ মহাদানশীল, মহাজ্ঞানী।" সূরা
  //          বাকারাহ- ২৬১
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/profile.php?id=100013304793161`,
  //    projectOrgName: "আল-বারাকাহ ফাউন্ডেশন",
  //    projectOrg: { _ID: "sdqme032", name: "আল-বারাকাহ ফাউন্ডেশন" },
  //    projectCreated: "June 2022",
  //    projectTag: "বন্যা, Sylhet",
  //    projectLink:
  //      "https://www.facebook.com/AlBarakahFoundationOfficial/posts/120402224016920",
  //  },

  //  {
  //    _ID: "sdqme033.1",
  //    projectImg: sylhetflood2,
  //    projectHeading: "গোয়াইনঘাট বন্যার্তদের সহায়তা",
  //    slug: "sylhet-flood-ghonaighat",
  //    projectDetails: (
  //      <div>
  //        <h3>
  //          গোয়াইনঘাট জৈন্তাপুরে বন্যার পরিস্তিতি ভয়ানক! আগের সব পানির রেকর্ড ভেঙে
  //          গেছে। আল্লাহ তা'য়ালা হেফাজতের মালিক
  //        </h3>
  //        <p>
  //          গোয়াইঘাটে উদ্ধার ও ত্রান কার্যক্রম পরিচালিত হচ্ছে মাওলানা নুমান
  //          আহমেদের উদ্যোগে গঠিত অস্থায়ী জরুরি সেবা ও পরামর্শ কেন্দ্র থেকে।
  //        </p>

  //        <p>
  //          ইমার্জেন্সি উদ্ধারের খবর পেলে সাথে সাথে নৌকা নিয়ে ছুটছে টিম, খাবার
  //          পৌঁছে দিচ্ছেন মানুষের ঘরে ঘরে বিশেষভাবে করে আশ্রয় কেন্দ্র গুলোতে, দিন
  //          রাত মানুষের সেবা চলছে বিরতিহীন,
  //        </p>
  //        <p>
  //          আপনারা সিলেট বা বাংলাদেশের যে কোন জায়গা থেকে গোয়াইঘাট অঞ্চলে ত্রান
  //          দিতে চাইলে মাওলানা নুমান আহমেদ সাহেবের সাথে যোগাযোগ করুন, উপযুক্ত
  //          জায়গায় আমানত পৌছে যাবে, অথবা গোয়াইনঘাট জৈন্তাপুরের কাহারও কিছু দরকার
  //          হলে যোগাযোগ করতে পারেন।
  //        </p>
  //        <p>
  //          ঠিকানাঃ ফতেপুর বাজার, গোয়াইনঘাট, সিলেট৷ (ইউনিয়ন অফিসের উত্তর পাশে,
  //          সিকদার মার্কেট)
  //        </p>
  //        <img
  //          src={sylhetghonaighat}
  //          alt="sylhetghonaighat"
  //          className="w-25 mt-3 mb-3"
  //        />
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">
  //          (গোয়াইনঘাট) বিকাশ, নগদ, রকেট: 01717566822, 01719546563
  //        </h4>
  //        <h4 className="mb-4">(জৈন্তাপুর) বিকাশ, নগদ: 01646885868</h4>

  //        <h5>যে কোনো ধরণের যোগাযোগঃ 01616398010</h5>

  //        <hr />

  //        {/*<p>1) Bank: Dutch Bangla Bank, Amborkhana, Sylhet </p>
  //          <p>A/C No: 201-151-26430 </p>
  //          <p>A/C Name: Mohammad Zakaria Raihan</p>
  //          <hr />
  //          <p>2) Bank: Pubali bank, Islamic Banking Window </p>
  //          <p>A/C No: 3487901001986 </p>
  //          <p>A/C Name: CHOWDHURY MOHIUL MOSTOFA FARHAN</p>

  //          <hr />*/}
  //        <p>
  //          "যারা আল্লাহর পথে ব্যয় করে, তাদের উপমা একটি শস্য-বীজের মত, যা থেকে
  //          সাতটি শীষ জন্মে, প্রতিটি শীষে থাকে একশত শস্য-দানা। আর আল্লাহ যার জন্য
  //          ইচ্ছা বহুগুণে বৃদ্ধি করে দেন। আল্লাহ মহাদানশীল, মহাজ্ঞানী।" সূরা
  //          বাকারাহ- ২৬১
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/nana.nani.73157/`,
  //    projectOrgName: "Numan Ahmed",
  //    projectOrg: { _ID: "sdqme033", name: "Numan Ahmed" },
  //    projectCreated: "June 2022",
  //    projectTag: "বন্যা, Sylhet",
  //    projectLink:
  //      "https://www.facebook.com/nana.nani.73157/posts/5747584838643216",
  //  },

  //  {
  //    _ID: "sdqme037.1",
  //    projectImg: sylhetmedmunir1,
  //    projectHeading: "ফ্রি মেডিকেল ক্যাম্প - ১",
  //    slug: "sylhet-medical-munir",
  //    projectDetails: (
  //      <div>
  //        <h3>
  //          গত মাসে আমরা সিলেটের ৩ টা পয়েন্টে ফ্রি মেডিকেল ক্যাম্প করেছিলাম। আমরা
  //          জানতাম না, সিলেটে দ্বিতীয়বারের মতো আমাদের যেতে হবে আবার!
  //        </h3>
  //        <p>
  //          সিলেটের বর্তমানে বড় বেশি প্রয়োজন স্বাস্থ্য সহায়তা। আমরা আমাদের
  //          সাধ্যমতো দুর্গত মানুষদের পাশে দাড়াবো। চাইলে আপনারাও আমাদের সহযাত্রী
  //          হতে পারেন!
  //        </p>
  //        <img
  //          src={sylhetmedmunir2}
  //          alt="sylhetghonaighat"
  //          className="w-25 mt-3 mb-3"
  //        />

  //        <p>
  //          কার্যক্রম
  //          <br />- ফ্রী মেডিকেল ক্যাম্প ও মেডিসিন বিতরণ
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">
  //          বিকাশ, নগদ, রকেট: 01738125107
  //          <br />
  //          Reference: "Sylhet" (Don't forget to add "Sadaqa made easy" too)
  //        </h4>

  //        <h5>অথবা ব্যাংক ট্রান্সফারঃ</h5>
  //        <p>
  //          Munir Foundation
  //          <br />
  //          Ac No: 20502 210203 678017
  //          <br />
  //          Islami Bank Bangladesh Ltd.
  //          <br />
  //          Sitakunda Branch, Chattagram
  //        </p>

  //        <hr />

  //        {/*<p>1) Bank: Dutch Bangla Bank, Amborkhana, Sylhet </p>
  //          <p>A/C No: 201-151-26430 </p>
  //          <p>A/C Name: Mohammad Zakaria Raihan</p>
  //          <hr />
  //          <p>2) Bank: Pubali bank, Islamic Banking Window </p>
  //          <p>A/C No: 3487901001986 </p>
  //          <p>A/C Name: CHOWDHURY MOHIUL MOSTOFA FARHAN</p>

  //          <hr />*/}
  //        <p>
  //          "যারা আল্লাহর পথে ব্যয় করে, তাদের উপমা একটি শস্য-বীজের মত, যা থেকে
  //          সাতটি শীষ জন্মে, প্রতিটি শীষে থাকে একশত শস্য-দানা। আর আল্লাহ যার জন্য
  //          ইচ্ছা বহুগুণে বৃদ্ধি করে দেন। আল্লাহ মহাদানশীল, মহাজ্ঞানী।" সূরা
  //          বাকারাহ- ২৬১
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://munirfoundation.org/`,
  //    projectOrgName: "Munir Foundation",
  //    projectOrg: { _ID: "sdqme037", name: "Munir Foundation" },
  //    projectCreated: "June 2022",
  //    projectTag: "সিলেট, Medical Camp",
  //    projectLink:
  //      "https://www.facebook.com/munirfoundation.org/photos/a.851771444899121/5186527568090132/",
  //  },

  //  {
  //    _ID: "sdqme038.1",
  //    projectImg: sylhetmedmanahil1,
  //    projectHeading: "ফ্রি মেডিকেল ক্যাম্প - ১",
  //    slug: "sylhet-medical-manahil",
  //    projectDetails: (
  //      <div>
  //        <h3>
  //          বন্যায় স্বাস্থ্য ঝুকিতে থাকা মানুষদের জন্য শুরু হয়েছে টিম মানাহিলের
  //          বিশেষ চিকিৎসা ক্যাম্প। ফ্রি চিকিৎসার পাশাপাশি বিনামূল্যে ঔষধও দেওয়া
  //          হচ্ছে।
  //        </h3>
  //        <p>
  //          আল মানাহিল নার্চার জেনারেল হাসপাতাল চট্টগ্রাম থেকে ডাক্তারগণ এসে
  //          সরাসরি চিকিৎসা সহায়তা দিচ্ছে সিলেটের প্রত্যন্ত অঞ্চলে।
  //        </p>

  //        <img
  //          src={sylhetmedmanahil2}
  //          alt="sylhetghonaighat"
  //          className="w-25 mt-3 mb-3"
  //        />
  //        <p>
  //          চিকিৎসা ক্যাম্প করা একটু ব্যয়বহুল হলেও আশা করি আপনাদের আমরা পাশে পাব।{" "}
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">
  //          01785 727920 (বিকাশ মার্চেন্ট, পেমেন্ট অপশন থেকে)।
  //          <br />
  //          01886 576775 ( বিকাশ পার্সোনাল )
  //          <br />
  //          01886 576776 ( বিকাশ পার্সোনাল )
  //          <br />
  //          01886 576775 (নগদ পার্সোনাল )
  //          <br />
  //          01886 5767753 (রকেট )
  //        </h4>

  //        <h5>Bank account:</h5>
  //        <p>
  //          Al Manahil Welfare Foundation Bangladesh
  //          <br />
  //          Ac No: 0100132954202
  //          <br />
  //          Janata Bank
  //          <br />
  //          Mimi Super Market Branch. Chattogram
  //        </p>

  //        <hr />

  //        <p>
  //          "যারা আল্লাহর পথে ব্যয় করে, তাদের উপমা একটি শস্য-বীজের মত, যা থেকে
  //          সাতটি শীষ জন্মে, প্রতিটি শীষে থাকে একশত শস্য-দানা। আর আল্লাহ যার জন্য
  //          ইচ্ছা বহুগুণে বৃদ্ধি করে দেন। আল্লাহ মহাদানশীল, মহাজ্ঞানী।" সূরা
  //          বাকারাহ- ২৬১
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://amwfb.org/`,
  //    projectOrgName: "Al Manahil Foundation",
  //    projectOrg: { _ID: "sdqme038", name: "Al Manahil Foundation" },
  //    projectCreated: "June 2022",
  //    projectTag: "সিলেট, Medical Camp",
  //    projectLink:
  //      "https://www.facebook.com/amwfborg/posts/pfbid0PYsSh5ofWgqm3cviUpSvWL4oQdHShUbVdbQcwcS6G7BHjC2kSEPv2dRcWfhZcDa5l",
  //  },

  //  {
  //    _ID: "sdqme035.1",
  //    projectImg: "sylhetflood3",
  //    projectHeading: "সিলেটে লাখ লাখ বন্যাদুর্গত",
  //    slug: "sylhet-sadaqah",
  //    projectDetails: (
  //      <div>
  //        <h3>
  //          আসসালামু আলাইকুম। সিলেটে স্মরণকালের ভয়াবহ বন্যায় লাখ লাখ বন্যাদুর্গত
  //          মানুষের পাশে দাঁড়াই।
  //        </h3>
  //        <p>
  //          এবারের বন্যায় সিলেট জেলায় সবচেয়ে বেশি প্লাবিত হয়েছে কোম্পানীগঞ্জ,
  //          কানাইঘাট, জকিগঞ্জ, জৈন্তাপুর, সদর, ও গোয়াইনঘাট উপজেলা।
  //        </p>
  //        <p>
  //          অধিকাংশ পরিবার পানির কারনে রান্না-বান্না করতে পারছেনা। আমরা সাদাকা টীম
  //          স্ব-শরীরে খাবার ও প্রয়োজনীয় ঔষধ নিয়ে বন্যার্তদের পাশে দাঁড়ানোর
  //          পরিকল্পনা হাতে নিয়েছি।
  //        </p>
  //        <p>
  //          আসুন সবাই সাধ্যমতো বন্যার্তদের পাশে দাঁড়ানোর চেষ্টা করি। আল্লাহ আমাদের
  //          সাদাকাগুলো কবুল করে নিক।
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <div className="mb-5">
  //          <h4>আপনার সাদাকা পাঠাতে পারেন নিচের পদ্ধতিতে</h4>
  //          <p>
  //            বিকাশ মার্চেন্টঃ 01903026868 (এই নাম্বারে আপনি 'পেমেন্ট' অপশনে গিয়ে
  //            টাকা পাঠাবেন)
  //          </p>
  //          <p>
  //            বিকাশ পার্সোনালঃ 01713639791, 01720475156 (এই নাম্বারে আপনি 'সেন্ড
  //            মানি' অপশনে গিয়ে টাকা পাঠাবেন){" "}
  //          </p>
  //          <p>রকেট পার্সোনালঃ 019196397911 </p>
  //          <p>নগদ পার্সোনালঃ 01919639791, 01822268474 </p>
  //          <p>
  //            ব্যাংকঃ Islami Bank Bangladesh. A/C no: 20501930203364301 A/C Name:
  //            MISBAH UDDIN AHMAD CDA Avenue Branch{" "}
  //          </p>
  //          <p>
  //            কেউ যদি নগদ অর্থে সাদাকা দিতে চান, কিংবা অন্য কোন ধরণের তথ্য
  //            অনুসন্ধানের জন্য যোগাযোগ করতে চান, তবে নিচের নাম্বারগুলোতে যোগাযোগ
  //            করুন। 01713639791, 01720475156{" "}
  //          </p>
  //          <p>জাযাকুমুল্লাহু খাইরান।</p>
  //        </div>
  //        <hr />
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/ahmedsagor.ctg/posts/2117211448445269`,
  //    projectOrgName: "সাদাকা",
  //    projectOrg: { _ID: "sdqme035", name: "সাদাকা" },
  //    projectCreated: "May 2022",
  //    projectTag: "বন্যা, Sylhet",
  //    projectLink:
  //      "https://www.facebook.com/sadaqahthecharity/posts/2015348435303864",
  //  },
];

export function getSylhetProjects() {
  return SylhetProjects;
}
